import Vue from 'vue'
import Router from 'vue-router'
import routes from "./routers"
Vue.use(Router)
const router = new Router({
    routes
});
router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token');
    if (to.name === 'my') {
        if (!token) {
            // 如果没有 token，则重定向到登录页面
            next('/signin');
          } else {
            // 如果有 token，则允许访问该页面
            next();
          }
    } else {
        next();
    }
    
});
router.afterEach(to => {
    window.document.title = to.meta.title;
    window.scrollTo(0, 0)
});
export default router
