const VITE_CHAIN_ID = 0x61;
const CHAIN_ID_16 = `0x${0x61.toString(16)}`;
const VITE_CHAIN_NAME = 'bsc 测试网络';
const VITE_CHAIN_RPC_URL = 'https://rpc.ankr.com/bsc_testnet_chapel'
const VITE_CHAIN_BLOCK_EXPLORER_URL = 'https://explorer1.icplaza.pro'

import detectEthereumProvider from "@metamask/detect-provider";
import Web3 from "web3/dist/web3.min.js";
import { KEY } from "@/static"
export async function fetchWeb3() {
  const provider = await detectEthereumProvider()
  const web3 = new Web3(provider)
  return web3
} 
export async function getSignRes(res, key = KEY, personal = true) {
  const web3 = await fetchWeb3()
  return personal ? web3.eth.personal.sign(key, res) : web3.eth.sign(key, res)
}
export async function getAddress() {
  if (!window.ethereum) {
    throw Error("ethereum is not found");
  }
  // window.web3 = new Web3(window.ethereum);
  // 获取当前链id
  const chainId = await window.ethereum.request({ method: "eth_chainId" });

  // 判断当前链id 是否是项目需要的链id
  if (chainId === VITE_CHAIN_ID) {
    // 获取地址返回地址
    const addr = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    if (!addr || addr.length == 0) {
      throw Error("get address fail");
    }
    // @ts-ignore
    return addr[0];
  } else {
    // 切换网络
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: CHAIN_ID_16 }],
      });
    } catch (e) {
      // 如果切换网络失败表示没有网络，需要添加网络
      // 添加网络
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: CHAIN_ID_16, // 网络ID
            chainName: VITE_CHAIN_NAME, // 网络名称
            nativeCurrency: { name: "icplaza", symbol: "ict", decimals: 18 },
            rpcUrls: [VITE_CHAIN_RPC_URL], // RPC节点URL
            blockExplorerUrls: [VITE_CHAIN_BLOCK_EXPLORER_URL], // 区块浏览器URL
          },
        ],
      });
      // 添加完成切换网络
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: CHAIN_ID_16 }],
      });
    }
  }

  // 获取地址返回
  const addr = await window.ethereum.request({ method: "eth_requestAccounts" });

  if (!addr || addr.length == 0) {
    throw Error("get address fail");
  }
  // @ts-ignore
  return addr[0];
}