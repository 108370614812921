export default {
  common: {
    area: "/uc/support/country",
  },
  uc: {
    //会员中心接口
    login: "/uc/login",
    register: "/uc/register",
    wallet: "/uc/asset/wallet/",
    spotWallet: "/uc/asset/spotWallet/",
    eryuanWallet: "/uc/asset/eryuanWallet/",
    captcha: "/uc/start/captcha",
    identification: "/uc/approve/certified/business/status", //商家认证
    apply: "/uc/approve/certified/business/apply", //商家认证申请
    announcement: "/uc/announcement/page", //公告列表
    news: "/uc/news/page",
    // paydividends:"/uc/bonus/user/",//持币分红
    paydividends: "/uc/bonus/user/page", //持币分红
    mylist: "/uc/mine/list_es", //交易挖矿
    mylistrecord: "/uc/mine/detail/", //交易挖矿详情
    addFollow: "/uc/follow/addFollow",
    applyNiuren: "/uc/follow/applyNiuren",
    getNiurenLockUSDT: "/uc/follow/getNiurenLockUSDT",
    getFollowFeeRate: "/uc/follow/getFollowFeeRate",
    followList: "/uc/follow/followList",
    cancelFollow: "/uc/follow/cancelFollow",
    contractAssetPNL: "/uc/asset/contractAssetPNL",
    rechargeAddressList: "/uc/recharge/address",
    coindetail: "/uc/coin/detail",
    coinBalance: "/uc/invest/trusteeship/balance", //  bibi余额
    profit: "/uc/invest/static/profit", // 查询收益钱包及明细
    rule: "/uc/invest/unlimited/rule", // 规则
    dayRate: "/uc/invest/unlimited/day-rate", // 日利率
    transferBiBi: "/uc/invest/static/transfer", // 划转到币币
    lockDetail: "/uc/invest/quota",
    goLock: "/uc/invest/trusteeship/submit", // 去锁仓
    lockTable: "/uc/invest/detail", // 锁仓表格
    profitObj: "/uc/invest/profit/statistics", // 收益对象
    statiTable: "/uc/invest/profit/staticDetail", // 收益表格
    profitStatus: "/uc/invest/profit/status", // 判断是否本轮收益出局 uc/invest/profit/status
    finsh: "/uc/invest/finish",
    type: "/uc/invest/type", // 投资详情
    findCollection: "/market/tickers/member-collection",
    addCollection: "/uc/collection/addCollection",
    cancelCollection: "/uc/collection/cancelCollection",
  },
  market: {
    //币币交易行情接口
    ws: "/market/market-ws",
    thumb: "/market/symbol-thumb",
    eryuanThumb: "/market/eryuan-symbol-thumb",
    thumbTrend: "/market/symbol-thumb-trend",
    plate: "/market/exchange-plate", //主动查询的盘口信息
    platemini: "/market/exchange-plate-mini", //获取10条数据
    platefull: "/market/exchange-plate-full", //获取所有数据
    trade: "/market/latest-trade", //主动查询的实时成交信息
    symbolInfo: "/market/symbol-info",
    indexData: "/market/index_info",
    eryuanCoinThumb: "/market/eryuan-symbol-coinThumb",

    spotThumb: "/market/spot-market/symbol-thumb",
    spotThumbTrend: "/market/spot-market/symbol-thumb-trend",
    spotPlate: "/market/spot-market/exchange-plate", //主动查询的盘口信息
    spotPlatemini: "/market/spot-market/exchange-plate-mini", //获取10条数据
    spotPlatefull: "/market/spot-market/exchange-plate-full", //获取所有数据
    spotTrade: "/market/spot-market/latest-trade", //主动查询的实时成交信息
    spotSymbolInfo: "/market/spot-market/symbol-info",
    spotCoinInfo: "/market/spot-market/coin-info",
    tickets: "/market/tickers/page-query", // 行情列表
    newtickets: "/market/newTickers/page-query", // 新币
    zone: "/market/tickers/zone", // 可选的交易币
  },
  exchange: {
    //币币交易委托提交与查询接口
    orderAdd: "/exchange/order/addOrder", //提交订单接口
    current: "/exchange/order/current", //当前委托接口
    history: "/exchange/order/history", //历史委托接口
    hold: "/exchange/order/hold", //历史委托接口
    closeList: "/exchange/order/closeList", //历史平仓接口
    personalCurrent: "/exchange/order/personal/current", //当前委托接口
    personalHold: "/exchange/order/personal/hold", //历史委托接口
    personalCloseList: "/exchange/order/personal/closeList", //历史平仓接口
    detail: "/exchange/order/detail/", //详细订单接口
    favorFind: "/exchange/favor/find", //查询自选
    favorAdd: "/exchange/favor/add", //添加自选
    favorDelete: "/exchange/favor/delete", //删除自选
    orderCancel: "/exchange/order/cancel", //取消委托
    orderClose: "/market/order/close", //取消委托
    orderCloseALL: "/exchange/order/closeAll", //取消委托
    getLeverage: "/exchange/exchange-coin/getLeverage",
    modifyStopProfitLoss: "/exchange/order/modifyStopProfitLoss", //修改止损止盈

    spotOrderAdd: "/exchange/spot-order/add", //提交订单接口
    spotOrderCurrent: "/exchange/spot-order/current", //当前委托接口
    spotOrderHistory: "/exchange/spot-order/history", //历史委托接口
    spotOrderDetail: "/exchange/spot-order/detail/", //详细订单接口
    spotOrderCancel: "/exchange/spot-order/cancel", //取消委托

    spotPersonalCurrent: "/exchange/spot-order/personal/current", //当前委托接口
    spotPersonalHistory: "/exchange/spot-order/personal/history", //当前委托接口
  },
  binaryOption: {
    symbolInfo: "/market/eryuan-symbol-info",
    orderAdd: "/market/eryuan-order/addOrder",
    current: "/market/eryuan-order/current", //当前委托接口
    history: "/market/eryuan-order/history", //历史委托接口
    personalCurrent: "/market/eryuan-order/personal/current", //当前委托接口
    personalHistory: "/market/eryuan-order/personal/history", //当前委托接口
  },
  otc: {
    coin: "/otc/coin/all", //查询支持的币种
    advertise: "/otc/advertise/page-by-unit", //获取广告
    createAd: "/uc/ad/create", //创建广告
    adDetail: "/otc/advertise/detail",
  },
};
