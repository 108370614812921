export default {
  	state: {
        auth:{
        	show:false
        },
        
		//1 C1待申请   
		//2 C1未通过   
		//3 C1通过   
		//4 C2待申请   
		//5 C2不通过
		//6 C2通过
        kycState:1,
  	},
  	getters: {},
 	mutations: {},
  	actions: {}
}

