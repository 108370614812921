<template>
    <div class="flexPage darkPage">
        <div class="flexContent">
            <div class="homePage">
                <div class="logo noLogin">
                    <span class="logoTitle">Function</span>
                    <!-- <img :src="$store.state.app.nightModel ? logo.white : logo.black" alt="logo" /> -->
                    <span class="message" @click="$router.push({ name: 'notice' })">
                        <img src="../assets/img/message.png" alt="">
                    </span>
                </div>
                <div class="ring">
                    <img src="@/assets/img/ring.png" alt="">
                    <van-notice-bar left-icon="" right-icon="arrow" :scrollable="false">
                        <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
                            <van-swipe-item v-for="(item, index) of noticeVO" :key="index"
                                @click="$router.push({ name: 'notice', params: { id: item.id } })">{{ item.title
                                }}</van-swipe-item>
                        </van-swipe>
                    </van-notice-bar>
                </div>
                <div class="detail">
                    <div class="detailBox">
                        <img src="../assets/img/home2.png" alt=""
                            style="width: 6.1069vw;height: 6.1069vw;margin: 0 2.0356vw 0 3.0534vw;">
                        <span class="invite">{{ $t('other.translate62') }}</span>
                        <img src="../assets/img/home4.png" alt=""
                            style="width: 1.5267vw;height: 1.5267vw;margin-left: 13.2316vw;">
                    </div>
                    <div class="detailBox">
                        <img src="../assets/img/home3.png" alt=""
                            style="width: 6.1069vw;height: 6.1069vw;margin: 0 2.0356vw 0 3.0534vw;">
                        <span class="invite">{{ $t('other.translate63') }}</span>
                        <img src="../assets/img/home4.png" alt=""
                            style="width: 1.5267vw;height: 1.5267vw;margin-left: 13.2316vw;">
                    </div>
                </div>
                <!-- <div class="buyCoin">{{ $t('other.translate61') }}</div>
                <div class="buyCoinBox">
                    <div class="buyCoinContent">How to buy coins quickly? Click here to enter!</div>
                    <img src="../assets/img/home6.png" alt="" style="width: 6.3613vw;height: 3.0534vw;margin-top: 3.0534vw;">
                </div> -->
                <div class="buyCoin" style="margin-top: 6.1069vw">{{ $t('other.translate64') }}</div>
                <!-- 轮播图
                <div class="indexSwipe">
                    <van-swipe :autoplay="3000" indicator-color="white">
                        <van-swipe-item v-for="(item, index) of bannerVO" :key="index" @click="clickBanner(item)">
                            <img :src="item.banner" alt="" />
                        </van-swipe-item>
                    </van-swipe>
                </div> -->
            </div>
            <!-- 公告 -->
            <!-- <div class="ring">
                <img src="@/assets/img/ring.png" alt="">
                <van-notice-bar left-icon="" right-icon="arrow" :scrollable="false">
                    <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
                        <van-swipe-item v-for="(item, index) of noticeVO" :key="index"
                            @click="$router.push({ name: 'notice', params: { id: item.id } })">{{ item.title
                            }}</van-swipe-item>
                    </van-swipe>
                </van-notice-bar>
                <van-icon style="margin-top: 5.0001px;" name="arrow" color="#616E85" />
            </div> -->
            <!-- 菜单列表 -->
            <!-- <van-grid square :border="false">
                <van-grid-item text="合约持仓">
                    <template #icon>
                        <img class="menus-icon" src="../assets/img/contract-position.png" alt="">
                    </template>
</van-grid-item>
<van-grid-item icon="photo-o" @click="goBIbi" text="币币持仓">
    <template #icon>
                        <img class="menus-icon" src="../assets/img/coin-holding-position.png" alt="">
                    </template>
</van-grid-item>
<van-grid-item @click="$mts.goto('trade')" icon="photo-o" text="合约">
    <template #icon>
                        <img class="menus-icon" src="../assets/img/contract.png" alt="">
                    </template>
</van-grid-item>
<van-grid-item icon="photo-o" @click="$mts.goto('assets')" text="充币">
    <template #icon>
                        <img class="menus-icon" src="../assets/img/coin-top-up.png" alt="">
                    </template>
</van-grid-item>
<van-grid-item @click="$mts.goto('service')" icon="photo-o" text="联系客服">
    <template #icon>
                        <img class="menus-icon" src="../assets/img/service.png" alt="">
                    </template>
</van-grid-item>
</van-grid> -->
            <!-- 快捷买币 -->
            <!-- <div class="buyCoin">
                <div class="buyCoinText">快捷买币</div>
                <div class="buyCoinContent">How to buy coins quickly?. Follow us for a safe and efficient way to buy
                    coins.</div>
                <div class="buyCoinBtn" @click="$mts.goto('otc')">GO</div>
            </div> -->
            <div class="flex parttwo-listunder">
                <div class="parttwo-listunderpart" v-for="item in showSportsUSDT" :key="item.id">
                    <div class="flex">
                        <span class="symbol">{{ item.symbol }}</span>
                    </div>
                    <div class="price mt12">${{ item.price }}</div>
                    <!-- <span class="cny-price">≈￥{{ item.cnyPrice }}</span> -->
                    <div class="flex">
                        <template v-if="item.isGreen">
                            <div class="fluctuate-icon">
                                +
                            </div>
                        </template>
                        <template v-else>
                            <div class="fluctuate-icon">
                            </div>
                        </template>
                        <span :class="[item.isGreen ? 'tiaopart-numbers' : 'tiaopart-numberf']">{{ item.chg }}</span>
                    </div>
                </div>
            </div>
            <!--  -->
            <div class="secondMenus homeMenus" style="padding: 0 4.0712vw;">
                <div class="tab">
                    <div class="tabBox" v-for="(item, index) in tabs" :key="index"
                        :class="{ active: typeActive == index }" @click="onChooseTab(index)">
                        {{ item.title }}
                    </div>
                </div>
                <!-- <div class="listTitle">
                    <span>币种</span>
                    <span>最新价格</span>
                    <span>涨跌幅</span>
                </div> -->
                <div>
                    <!-- 涨幅榜 -->
                    <ul class="coinlist">
                        <div v-for="(item, index) of spotCoins.USDT" :key="index"
                            style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 6.1069vw;"
                            @click="
                                $router.push({
                                    name: 'tradeDetail',
                                    params: { symbol: item.symbol },
                                })
                                ">
                            <div class="clleft">
                                <div>
                                    <img class="icon" :src="item.icon" alt=""
                                        style="width: 8.1425vw;height: 8.1425vw;margin-right: 2.0356vw;">
                                </div>
                                <div class="clright">
                                    <div style="width: 100%;margin-left: 5px;"><b class="value">{{ item.coin }}</b></div>
                                    <div style="display: flex;margin-left: 3px;">
                                        <template v-if="item.isGreen">
                                            <div class="fluctuate-icon">
                                                +
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="fluctuate-icon">
                                            </div>
                                        </template>
                                        <span :class="[item.isGreen ? 'tiaopart-numbers' : 'tiaopart-numberf']">{{
                                            item.chg }}%</span>
                                    </div>
                                </div>

                            </div>
                            <div class="clcenter">
                                <p class="value">$ {{ item.price }}</p>
                                <!-- <p style="margin-top: -2.5445vw;"><span>≈￥{{ item.cnyPrice }}</span></p> -->
                            </div>

                        </div>
                    </ul>
                </div>
            </div>
        </div>
        <downbar />
    </div>
</template>
<script>
var Stomp = require('stompjs');
var SockJS = require('sockjs-client');
import downbar from "@/views/modules/downbar";
import { getAddress } from "../utils/address";
export default {
    data() {
        return {
            type: "rise",
            typeActive: 0,
            tabs: [
                { title: this.$t("home.sm1"), active: "active" },
                { title: this.$t("home.sm2"), active: "" },
                { title: this.$t("home.sm3"), active: "" },
            ],
            logo: {
                white: require("../assets/img/logo2.png"),
                black: require("../assets/img/logo2.png"),
            },
            bannerVO: [],
            noticeVO: [{ title: "" }],
            rankingVO: [],
            drawVO: [],
            polling: null,
            interval: null,
            sRanking: [],
            sRankingSize: 0,



            // coins: {
            //     _map: [],
            //     USDT: []
            // },
            spotCoins: {
                _map: [],
                USDT: []
            },
            showSportsUSDT: [],
        };
    },
    components: { downbar },
    watch: {
        typeActive(newValue, oldValue) {
            console.log(newValue);
            switch (newValue) {
                case 0:
                    this.spotCoins.USDT.sort((a, b) => b.chg - a.chg);
                    break;
                case 1:
                    this.spotCoins.USDT.sort((a, b) => a.chg - b.chg);
                    break;
                case 2:
                    // this.getSymbol();
                    break;
            }
        }
    },
    computed: {
        isLogin() {
            return this.$store.state.isLogin;
        },
    },
    created() { },
    mounted() {
        this.getBanner();
        this.getNotice();
        // this.getSymbol();
        this.getSpotSymbol();
        // console.log("dizhi", getAddress());
    },
    methods: {
        goBIbi() {
            this.$mts.goto('otherOrder')
            this.$store.commit("setParamsDataIsaac", {
                pageNo: 1,
                pageSize: 10,
                symbol: null,
            });
            this.$store.commit("setParamsDataIsaacAll", {
                pageNo: 1,
                pageSize: 10,
                symbol: null,
            });
        },
        formatName(typeActive) {
            let that = this;
            switch (typeActive) {
                //涨幅榜
                case 0:
                    that.type = "rise";
                    break;
                //跌幅榜
                case 1:
                    that.type = "fall";
                    break;
                //新币榜
                case 2:
                    that.type = "coin";
                    break;
            }
        },

        getSpotCoin(symbol) {
            return this.spotCoins._map[symbol];
        },

        // 获取轮播图
        getBanner() {
            this.$http.post(this.host + '/uc/ancillary/system/advertise', {}).then((response) => {
                var resp = response.body;
                if (resp.code !== 0) return;
                this.bannerVO = resp.data;
            });
        },

        // 获取公告
        getNotice() {
            this.$http.post(this.host + '/uc/news/page', {}).then((response) => {
                var resp = response.body;
                if (resp.code !== 0) return;
                this.noticeVO = resp.data.content;
            });
        },

        onChooseTab(index) {
            this.typeActive = index;
        },

        // getSymbol() {
        //     this.$http.post(this.host + '/market/spot-market/symbol-thumb-trend', {}).then((response) => {
        //         var resp = response.body;
        //         for (var i = 0; i < resp.length; i++) {
        //             var coin = resp[i];
        //             coin.price = resp[i].close.toFixed(resp[i].baseCoinScale);
        //             coin.rose = resp[i].chg > 0 ? '+' + (resp[i].chg * 100).toFixed(2) + '%' : (resp[i].chg * 100).toFixed(2) + '%';
        //             coin.coin = resp[i].symbol.split('/')[0];
        //             coin.base = resp[i].symbol.split('/')[1];
        //             coin.isGreen = resp[i].chg > 0 ? true : false;
        //             coin.high = resp[i].high.toFixed(resp[i].baseCoinScale);
        //             coin.low = resp[i].low.toFixed(resp[i].baseCoinScale);
        //             coin.href = (coin.coin + '_' + coin.base).toLowerCase();
        //             coin.turnover = parseInt(resp[i].volume);
        //             coin.isFavor = false;
        //             this.coins._map[coin.symbol] = coin;
        //             this.coins[coin.base].push(coin);
        //         }
        //         this.coins.USDT.forEach((item) => {
        //             if (item.coin == 'BTC') {
        //                 this.showSportsUSDT.push(item);
        //             } else if (item.coin == 'ETH') {
        //                 this.showSportsUSDT.push(item);
        //             }
        //         });
        //         this.startWebsock();
        //     });
        // },
        getSpotSymbol() {
            this.$http.post(this.host + '/market/spot-market/symbol-thumb-trend', {}).then((response) => {
                var resp = response.body;
                for (var i = 0; i < resp.length; i++) {
                    var coin = resp[i];
                    coin.price = resp[i].close.toFixed(resp[i].baseCoinScale);
                    coin.rose = resp[i].chg > 0 ? '+' + (resp[i].chg * 100).toFixed(2) + '%' : (resp[i].chg * 100).toFixed(2) + '%';
                    coin.coin = resp[i].symbol.split('/')[0];
                    coin.base = resp[i].symbol.split('/')[1];
                    coin.isGreen = resp[i].chg > 0 ? true : false;
                    coin.high = resp[i].high.toFixed(resp[i].baseCoinScale);
                    coin.low = resp[i].low.toFixed(resp[i].baseCoinScale);
                    coin.href = (coin.coin + '_' + coin.base).toLowerCase();
                    coin.turnover = parseInt(resp[i].volume);
                    coin.isFavor = false;
                    this.spotCoins._map[coin.symbol] = coin;
                    this.spotCoins[coin.base].push(coin);


                    if (coin.coin == 'BTC') {
                        this.showSportsUSDT.push(coin);
                    } else if (coin.coin == 'ETH') {
                        this.showSportsUSDT.push(coin);
                    }
                }

                this.startWebsock();
            });
        },
        startWebsock() {
            var stompClient = null;
            var that = this;
            var socket = new SockJS(that.host + '/market/market-ws');
            stompClient = Stomp.over(socket);
            stompClient.debug = false;
            stompClient.connect({}, function (frame) {
                stompClient.subscribe('/topic/spot-market/thumb', function (msg) {
                    var resp = JSON.parse(msg.body);
                    var coin = that.getSpotCoin(resp.symbol);
                    if (coin != null) {
                        coin.price = resp.close.toFixed(resp.baseCoinScale);
                        coin.rose = resp.chg > 0 ? '+' + (resp.chg * 100).toFixed(2) + '%' : (resp.chg * 100).toFixed(2) + '%';
                        coin.close = resp.close.toFixed(resp.baseCoinScale);
                        coin.high = resp.high.toFixed(resp.baseCoinScale);
                        coin.low = resp.low.toFixed(resp.baseCoinScale);
                        coin.turnover = parseInt(resp.volume);
                    }
                });
            });
        }
    },
};
</script>

<style scoped>
.ring {
    padding: 0 21px;
    border: .2545vw solid #F6F6F6;
    border-radius: 4.5802vw;
    background: transparent !important;
    margin-top: 11.9999px;
    display: flex;
    align-items: center;
    height: 9.6692vw !important;
    box-sizing: border-box;
}

.ring .van-notice-bar {
    flex: 1;
}

.ring .notice-swipe {
    height: 10.1781vw;
    background-color: transparent;

}

.notice-swipe .van-swipe__track .van-swipe-item {
    width: 100% !important;
    color: #616E85;
    font-size: 11.9999px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    background-color: transparent;
}

.ring .van-notice-bar .van-notice-bar__wrap {
    height: 11.1959vw;
    line-height: 11.1959vw;
    background-color: transparent;
}

.ring img {
    width: 20.0002px;
    height: 20.0002px;
}


.tabBox {
    height: 8.1425vw;
    width: auto;
    min-width: 21.1196vw;
    text-align: center;
    line-height: 8.1425vw;
}

.active {
    background-color: #000;
    color: #fff;
    border-radius: 2.0356vw;
}

.tab {
    width: 100%;
    height: 10.1781vw;
    background: #F6F6F6;
    border-radius: 2.0356vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: Inter;
    font-size: 3.5623vw;
    font-weight: 500;
    margin: 6.1069vw 0 4.0712vw 0
}

.buyCoinContent {
    font-family: Inter;
    font-size: 3.0534vw;
    font-weight: 400;
    line-height: 3.0534vw;
    color: #00000080;
    width: 38.4224vw;


}

.buyCoinBox {
    width: 100%;
    height: 20.3562vw;
    border: .2545vw solid #F6F6F6;
    border-radius: 2.0356vw;
    position: relative;
    padding-top: 4.0712vw;
    padding-left: 4.0712vw;
    margin-top: 2.0356vw;
}

.buyCoinBox::after {
    content: '';
    width: 22.9008vw;
    height: 20.3562vw;
    background: url("../assets/img/home5.png") no-repeat;
    background-size: 100%;
    position: absolute;
    top: 0;
    right: 4.8346vw;
}

.buyCoin {
    font-family: Inter;
    font-size: 4.0712vw;
    font-weight: 600;

}

.invite {
    font-family: Inter;
    font-size: 3.0534vw;
    font-weight: 500;
}

.detailBox {
    width: 42.2392vw;
    height: 12.2137vw;
    background: #F6F6F6;
    border-radius: 2.0356vw;
    display: flex;
    align-items: center;
}

.detail {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6.1069vw;
    margin-bottom: 6.1069vw;
}

::v-deep .van-search__content {
    width: 75.0636vw !important;
    height: 9.1603vw !important;
    border-radius: 4.5802vw !important;
    border: .2545vw solid #F6F6F6;
    background-color: transparent;
    margin-left: -5.0891vw;
}

::v-deep .van-search {
    width: 75.0636vw !important;
    height: 9.1603vw !important;
    border-radius: 4.5802vw !important;
    background-color: transparent;
}

::v-deep .van-field__control {
    background-color: transparent !important;
    font-size: 3.5623vw;
    margin-top: -1.2723vw;
}

::v-deep .van-icon-clear {
    margin-top: -4.0712vw;
}

.logoTitle {
    font-family: Inter;
    font-size: 5.0891vw;
    font-weight: 500;
    line-height: 7.1247vw;
}

.mt12 {
    margin-top: .75rem;
}

.homePage {
    padding: 0 4.0712vw;
}

.homePage .logo .message img {
    width: 6.1069vw;
    height: 6.1069vw;
}

.menus-icon {
    width: 3.125rem;
    height: 3.125rem;
}

.van-grid .van-grid-item--square .van-grid-item__content--center .van-grid-item__text {
    color: #0D1421;
}

.parttwo-listunder {
    gap: .5625rem;
    padding: 0 .9825rem;
    box-sizing: border-box;
}

.flex {
    display: flex;
    align-items: center;
    overflow-x: auto;
}

.parttwo-listunder .parttwo-listunderpart {
    width: 50.8906vw;
    min-height: 25.9542vw;
    height: auto;
    box-sizing: border-box;
    border-radius: .5rem;
    border: .2545vw solid #F6F6F6;
    padding: 3.0534vw 0 0 4.0712vw;
    margin-top: 2.0356vw;
    margin-left: 2.5445vw;
}

.parttwo-listunder .parttwo-listunderpart .icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: .25rem;
}

.parttwo-listunder .parttwo-listunderpart .symbol {
    font-family: Inter;
    font-size: 3.5623vw;
    font-weight: 600;

}

.parttwo-listunder .parttwo-listunderpart .price {
    font-family: Inter;
    font-size: 5.0891vw;
    font-weight: 500;
}

.fluctuate-icon {
    margin-top: -0.2456rem;
    margin-right: .1875rem;
    width: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00000080
}

.fluctuate-icon img {
    width: .5rem;
}

.tiaopart-numbers {
    font-size: 3.0534vw;
    font-weight: 400;
    color: #00000080;
}

.tiaopart-numberf {
    font-size: 3.0534vw;
    font-weight: 400;
    color: #00000080;
    margin-left: -2.0356vw;
}

.coinlist div .clleft .icon {
    width: 2rem;
    height: 2rem;
    margin-right: .25rem;
}

.coinlist div .clleft {
    width: 25.4453vw;
    display: flex;
    align-items: center
}

.coinlist div .clright {
    width: 25.4453vw;
    display: flex;
    flex-wrap: wrap;
}

.coinlist div .clcenter .value {
    color: #000;
    font-size: 3.5623vw;
    font-weight: 500
}

.coinlist div .clcenter {
    width: 19.7176vw;
}

.ring {
    padding: 0 21px;
    background: #EFF0F2;
    margin-top: 11.9999px;
    display: flex;
    align-items: center;
    height: 43.9999px;
    box-sizing: border-box;
}

.ring .van-notice-bar {
    flex: 1;
    background-color: transparent;
}

.ring .notice-swipe {
    height: 2.5rem;
}

.notice-swipe .van-swipe__track .van-swipe-item {
    width: 100% !important;
    color: #616E85;
    font-size: 11.9999px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ring .van-notice-bar .van-notice-bar__wrap {
    height: 43.9999px;
    line-height: 43.9999px;
}

.ring img {
    width: 20.0002px;
    height: 20.0002px;
}
</style>
