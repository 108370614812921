<template>
    <div class="" style="height: 83px;">
        <div class="dh"></div>
        <van-tabbar :value="activeIndex" class="downbar">
            <van-tabbar-item @click.native="$mts.goto('home')">
                <!-- <i class="iconfont iconhome" slot="icon"></i> -->
                <img src="../../assets/img/homeOff.png"  class="img1" :lang="$i18n.locale" alt="" v-if="activeIndex != 0"
                    style="width: 24px;height: 24px;">
                <img src="../../assets/img/homeOn.png"  class="img1" :lang="$i18n.locale" alt="" v-if="activeIndex == 0" style="width: 24px;height: 24px;">
                <div class="tag2" :lang="$i18n.locale"
                    :style="{ color: activeIndex != 0 ? '##808080' : '#000', marginTop: '4px' }">{{ $t('nav.n1') }}
                </div>
            </van-tabbar-item>
            <van-tabbar-item @click.native="$mts.goto('trade', { symbol: 'BTC_USDT' })">
                <img src="../../assets/img/contractOff.png" class="img" :lang="$i18n.locale" alt=""
                    v-if="activeIndex != 1" style="width: 24px;height: 24px;">
                <img src="../../assets/img/contractOn.png" class="img" :lang="$i18n.locale" alt=""
                    v-if="activeIndex == 1" style="width: 24px;height: 24px;">
                <div :lang="$i18n.locale" :style="{ color: activeIndex != 1 ? '##808080' : '#000', marginTop: '4px', }"
                    class="tag1">
                    {{ $t('other.translate4') }}
                </div>
            </van-tabbar-item>
            <van-tabbar-item @click.native="$mts.goto('tradeCoin', { symbol: 'BTC_USDT' })">
                <img src="../../assets/img/CoinsOff.png" class="img" :lang="$i18n.locale" alt="" v-if="activeIndex != 2"
                    style="width: 24px;height: 24px;">
                <img src="../../assets/img/CoinsOn.png" class="img" :lang="$i18n.locale" alt="" v-if="activeIndex == 2"
                    style="width: 24px;height: 24px;">
                <div class="tag1" :lang="$i18n.locale"
                    :style="{ color: activeIndex != 2 ? '##808080' : '#000', marginTop: '4px', }">
                    {{ $t('other.translate5') }}
                </div>
            </van-tabbar-item>
            <van-tabbar-item @click.native="$mts.goto('my')">
                <img src="../../assets/img/MineOff.png" class="img2" :lang="$i18n.locale" alt="" v-if="activeIndex != 4"
                    style="width: 24px;height: 24px;">
                <img src="../../assets/img/MineOn.png" class="img2" :lang="$i18n.locale" alt="" v-if="activeIndex == 4" style="width: 24px;height: 24px;">
                <div class="tag2" :lang="$i18n.locale"
                    :style="{ color: activeIndex != 4 ? '##808080' : '#000', marginTop: '4px' }">
                    {{ $t('other.translate3') }}
                </div>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>
export default {
    // data(){
    //     return {
    //
    //     }
    // },
    components: {},
    watch: {},
    computed: {
        activeIndex() {
            switch (this.$route.name) {
                case "home":
                    return 0;
                case "trade":
                    return 1;
                case "tradeCoin":
                    return 2;
                case "otc":
                    return 3;
                case "my":
                    return 4;
                default:
                    return 100;
            }
        },
        chatCount() {
            if (this.$store.state.isLogin) {
                return this.$store.state.newChat.count > 0 ? this.$store.state.newChat.count : '';
            }
            return '';
        }
    },
    mounted() {

    },
    methods: {}
}
</script>
<style>
.tag1:lang(en-US) {
    width: 100px;
    text-align: center;
}
.img:lang(en-US) {
    margin-left: 40px
}
.img1:lang(en-US) {
    margin-left: 20px
}
.img2:lang(en-US) {
    margin-left: -2px
}
.tag1:lang(zh-CN) {
    margin-left: -11px
}
.tag2:lang(zh-CN) {
    margin-left: 0px
}
</style>
