export const KEY = 'D6AD77394D8AE48DA983CBDE2779863B'
export const imgUrl = process.env.NODE_ENV === 'development' ? 'http://47.102.185.15:8040' : 'http://47.102.185.15:8040'
export const apiUrl = process.env.NODE_ENV === 'development' ? '/api' : 'http://47.102.185.15:8050'

// #nft市场
export const nftUrl = 'http://47.103.86.163:8050/'

export const baseTime = {
  hour: 60 * 60 * 1000,
  min: 60 * 1000,
  second: 1000
}

export const site = {
  MINING: 'mining',
  CHICKEN: 'chicken',
  COW: 'cow',
  PLANT: 'plant'
}

export const BOTTOM_NAV = {
  HOME: 'home',
  CHEST: 'chest',
  SMITHY: 'smithy',
  EXCHANGE: 'exchange',
  MARKET: 'market'
}

// export const bottomNav = [
//   {
//     url: require('@/assets/img/nav/Home.png'),
//     name: BOTTOM_NAV.HOME
//   },
//   {
//     url: require('@/assets/img/nav/Chest.png'),
//     name: BOTTOM_NAV.CHEST
//   },
//   {
//     url: require('@/assets/img/nav/Smithy.png'),
//     name: BOTTOM_NAV.SMITHY
//   },
//   {
//     url: require('@/assets/img/nav/Exchange.png'),
//     name: BOTTOM_NAV.EXCHANGE
//   },
//   {
//     url: require('@/assets/img/nav/Market.png'),
//     name: BOTTOM_NAV.MARKET
//   }
// ]

// export const siteInfo = [
//   {
//     name: site.MINING,
//     url: require('@/assets/img/nav/mining-side.png')
//   },
//   {
//     name: site.CHICKEN,
//     url: require('@/assets/img/nav/chicken-side.png')
//   },
//   {
//     name: site.COW,
//     url: require('@/assets/img/nav/cow-side.png')
//   },
//   {
//     name: site.PLANT,
//     url: require('@/assets/img/nav/plant-side.png')
//   }
// ]

export const exchange = {
  WITHDRAW: 'withdraw',
  DEPOSIT: 'deposit'
}

export const goods = {
  MINING_GOOD: 'MINING_GOOD',
  CHICKEN_GOOD: 'CHICKEN_GOOD',
  PLANT_GOOD: 'PLANT_GOOD',
  COW_GOOD: 'COW_GOOD',
  VIP: 'VIP'
}

export const COW_FOOD = {
  NORMAL: 0,
  BREED: 1
}

export const BASE_RESOURCE = {
  WOOD: 1,
  MEAT: 2,
  GOLD: 3
}
export const BASE_RESOURCE_TEXT = {
  1: 'wood',
  2: 'food',
  3: 'gold',
  8: 'wood',
  9: 'food',
  10: 'gold'
}

export const GOOD_LIST = { 5: 'barley', 6: 'corn', 9: 'egg', 18: 'milk' }

export const RESOURCE_LIST = {
  1: 'wood',
  2: 'food',
  3: 'gold',
  8: 'wood',
  9: 'food',
  10: 'gold'
}

// export const BASE_IMG = {
//   5: require('@/assets/img/base/wheat.png'),
//   6: require('@/assets/img/base/corn.png'),
//   9: require('@/assets/img/base/egg.png'),
//   10: require('@/assets/img/base/small-chicken.png'),
//   11: require('@/assets/img/base/chicken.png'),
//   12: require('@/assets/img/base/calf.png'),
//   13: require('@/assets/img/base/calf-b.png'),
//   14: require('@/assets/img/base/bull.png'),
//   15: require('@/assets/img/base/dairy-cow.png')
// }

export const BASE_INFO = {
  1: { name: 'Fishing Rog', level: 1 },
  2: { name: 'Farm Plot' },
  3: { name: 'Corn Seed' },
  4: { name: 'Barley Seed' },
  5: { name: 'Barley' },
  6: { name: 'Corn' },
  7: { name: 'Coop' },
  8: { name: 'Cowshed' },
  9: { name: 'Chicken Egg', reward: 10 },
  10: { name: 'Chick', reward: 11 },
  11: { name: 'Chicken' },
  12: { name: 'Baby Calf', reward: 13 },
  13: { name: 'Calf', reward: -1 },
  14: { name: 'Bull' },
  15: { name: 'Dairy Cow', reward: 18 },
  18: { name: 'Milk' },
  19: { name: 'Fishing Net', level: 2 },
  20: { name: 'Fishing Boat', level: 3 },
  21: { name: 'Stone Axe', level: 1 },
  22: { name: 'Axe', level: 1 },
  23: { name: 'Saw', level: 2 },
  24: { name: 'Chainsaw', level: 3 },
  25: { name: 'Mining Excavator', level: 1 },
  26: { name: 'Ancient Stone Axe', level: 1 },
  29: { name: 'Bronze Member - wood' },
  30: { name: 'Sliver Member - wood' },
  31: { name: 'Gold Member - wood' },
  32: { name: 'Diamond Member - wood' },
  33: { name: 'Bronze Member - food' },
  34: { name: 'Sliver Member - food' },
  35: { name: 'Gold Member - food' },
  36: { name: 'Diamond Member - food' },
  37: { name: 'Bronze Member - gold' },
  38: { name: 'Sliver Member - gold' },
  39: { name: 'Gold Member - gold' },
  40: { name: 'Diamond Member - gold' }
}

export const LEVEL_INFO = ['', 'Common', 'Uncommon', 'Rare']
export const ERROR_LIST = {
  Failure: 'Failure',
  Success: 'Success',
  1: 'Not enough gold coins',
  2: 'Not enough energy',
  3: 'Not enough wood',
  4: 'not enough food',
  1001: 'Daily operation limit',
  1002: 'Unable to remove equipment while cooling down',
  1003: 'Without a spouse',
  1004: 'Two responses of this type are already equipped',
  1005: 'Failure',
  1006: 'The user has no current props',
  1007: 'Equipment is being worn',
  1008: 'Exceeds the number of equitable tools',
  1009: 'Not enough',
  1010: 'Map already exists',
  1011: 'Cooling',
  1012: 'Insufficient balance',
  1013: 'Reach the planting limit',
  1014: 'Scene not unlocked',
  1015: 'Not farm props'
}

/**
  1: '黄金不够',
  2: '能量不够',
  3: '木材不够',
  4: '食物不够',
  1001: '操作上限',
  1002: '冷却中',
  1003: '没有配偶',
  1004: '当前已经装备2张该类型会员',
  1005: '失败',
  1006: '该用户无当前道具',
  1007: '装备正在穿戴中',
  1008: '超过可装备工具个数',
  1009: '数量不足',
  1010: '地图已存在',
  1011: '冷却中',
  1012: '余额不足',
  1013: '达到种植上限',
  1014: '场景未解锁',
  1015: '不是农场道具'
 */

export const ERROR_LIST_SP = Object.keys(ERROR_LIST).map((i) => Number(i))
