import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import user from "./modules/user";
import app from "./modules/app";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiSecret: "!@`tcrs87261%$#",
    loadIsaac: false,
    timestamp: 0,
    paramsDataIsaac: {},
    paramsDataIsaacAll: {},
    coinsList: [],
    currentCoin: {},
    member: null,
    contractAssetPNLAll: {},
    currentCoinAll: {},
    holdOrderAll: {},
    currentOrderAll: {},
    closeOrderAll: {},
    historyOrderAll: {},
    currentOrderAlls: {},
    coin: {}, // 货币配置信息
    rate: {}, //汇率配置
    isLogin: false, // 用户是否登录
    isAPP: false, //是否APP
    symbol: "",
    scrollTop: 0,
    ws: {
      // websocket 返回数据
      tick: {}, // 交易行情
      capital: {}, // 资产
      buys: null, // 买单
      sells: null, //卖单
      history: [], // 成交历史
      actives: null, // 委托数据
    },
    currency: "CNY",
    nowCurrency: "CNY",
    favor: [], // 用户自选列表
    webSocket: null,
    chatWs: null,
    drawWs: null,
    chatData: {
      no: "", //订单编号
      list: [], //聊天记录
    },
    newChat: {
      timestamp: -1,
      count: 0,
    }, // 记录数据
    openWs: false,
    tradeWs: false,
    bidask: true, // 买卖单都更新
    bid: false,
    ask: false,
    onload: false,
  },
  mutations: {
    setLoginStatus(state, status) {
      state.isLogin = status;
    },
    SET_HISTORY_ORDER_ALL(state, payload) {
      state.historyOrderAll = payload;
    },
    setCurrentOrderAlls(state, payload) {
      state.currentOrderAlls = payload;
    },
    setContractAssetPNLAll(state, payload) {
      state.contractAssetPNLAll = payload;
    },
    setCurrentCoinAll(state, payload) {
      state.currentCoinAll = payload;
    },
    setHoldOrderAll(state, payload) {
      state.holdOrderAll = payload;
    },
    setCurrentOrderAll(state, payload) {
      state.currentOrderAll = payload;
    },
    setCloseOrderAll(state, payload) {
      state.closeOrderAll = payload;
    },
    setLodaIsaac(state, payload) {
      state.loadIsaac = payload;
    },
    setParamsDataIsaac(state, payload) {
      state.paramsDataIsaac = payload;
    },
    setParamsDataIsaacAll(state, payload) {
      state.paramsDataIsaacAll = payload;
    },
    setParamsIsaacOne(state, payload) {
      state.paramsIsaacOne = payload;
    },
    setParamsIsaacTow(state, payload) {
      state.paramsIsaacTow = payload;
    },
    setParamsIsaacThree(state, payload) {
      state.paramsIsaacThree = payload;
    },
    setMember(state, member) {
      state.member = member;
      localStorage.setItem("MEMBER", JSON.stringify(member));
    },
  },
  getters: {
    member(state) {
      return state.member;
    },
  },
  actions: {},
  modules: {
    user,
    app,
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
