import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vant from "vant";
import axios from "axios";
import icons from "@/components/icons";
import nodata from "@/components/nodata";
import loading from "@/components/loading";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import VueClipboard from "vue-clipboard2";
import VueResource from "vue-resource";

import config from "@/config";
import mts from "@/assets/js/method";
import tool from "@/assets/js/tool";
import tdp from "@/assets/js/tradePage";
import vld from "@/assets/js/validator";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Api from "./config/api";

// 语言切换
import VueI18n from "vue-i18n";
import locales from "@/locales";

import $ from "jquery";
window.$ = window.jQuery = $;

import "@/assets/lib/mui/css/mui.css";
import mui from "@/assets/lib/mui/js/mui.min.js";
window.mui = mui;

//加载样式
import "ionicons/dist/css/ionicons.min.css";
import "vant/lib/index.css";
import "@/assets/css/main.css";

import "./assets/iconfont/font_1379542_tfbfssct0mh.css";

Vue.use(VueI18n);
Vue.use(icons);
Vue.use(vant);
Vue.use(nodata);
Vue.use(loading);
Vue.use(VueClipboard);
Vue.use(VueResource);
Vue.use(ElementUI);
Vue.component(VueQrcode.name, VueQrcode);

Vue.prototype.$bus = new Vue();
Vue.prototype.$mts = mts;
Vue.prototype.$tdp = tdp;
Vue.prototype.$vld = vld;
Vue.prototype.$tool = tool;
Vue.prototype.$config = config;
Vue.prototype.$axios = axios;
Vue.prototype.api = Api;

// Vue.prototype.host = "https://exch1api.bullbi.com";
Vue.prototype.host = "https://exch1api.web3dev.vip";

// Vue.prototype.host = "http://192.168.1.5";
// Vue.prototype.host = "http://192.168.110.53";

// Vue.prototype.api = Api;
Vue.http.options.credentials = true;
Vue.http.options.emulateJSON = true;
Vue.http.options.headers = {
  "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  // 'Content-Type': 'application/json;charset=utf-8'
};
Vue.filter("toFixed", function(number, scale) {
  return new Number(number).toFixed(scale);
});
// Vue.config.productionTip 				= false
// axios.defaults.withCredentials 			= true;
// // axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

Vue.http.interceptors.push((request, next) => {
  //登录成功后将后台返回的TOKEN在本地存下来,每次请求从sessionStorage中拿到存储的TOKEN值
  request.headers.set("x-auth-token", localStorage.getItem("token"));
  next((response) => {
    //登录极验证时需获取后台返回的TOKEN值
    var xAuthToken = response.headers.get("x-auth-token");
    if (xAuthToken != null && xAuthToken != "") {
      localStorage.setItem("token", xAuthToken);
    }
    //
    if (response.body.code == "4000" || response.body.code == "3000") {
      router.push("/signin");
    }
    return response;
  });
});

// 自动设置多语言
const navLang = navigator.language;
console.log("u0nmnm", navLang);
const localLang = navLang === "zh-CN" || navLang === "en-US" ? navLang : false;
let lang = window.localStorage.getItem("language") || "en-US";
localStorage.setItem("language", lang);
const i18n = new VueI18n({
  locale: lang,
  messages: locales,
  silentTranslationWarn: true,
});

window._vm = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
