const lang = {
    home: {
        notice: '',
        i1: '官方公告',
        i2: '新手指導',
        i3: '合約',
        i4: 'APP下載',
        i5: '線上客服',
        sm1: '漲幅榜',
        sm2: '跌幅榜',
        sm3: '新幣榜',
        l1: '交易對',
        l2: '價格',
        l3: '漲跌幅',
        sl: '量',
        download: {
            android: '安卓下載',
            IOS: 'IOS下載'
        },
        turntable: {
            lucky: '幸運大轉盤',
            winlist: '中獎名單',
            myprize: '我的獎品',
            notimes: '抽獎次數不足',
            thanks: '謝謝參與',
            thankJoin: '謝謝<br/>參與',
            start: '立即<br/>抽獎',
            your: '您目前有',
            yourtimes: '次抽獎機會',
            gettimes: '獲取抽獎機會',
            recommend: '推薦好友',
            h1: '操作(下單)10手，<br/>獲得1次抽獎機會',
            h2: '每直推10人(KYC C2)<br/>獲得1次抽獎機會',
            o1: '立即<br/>下單',
            o2: '立即<br/>邀請',
            user: '使用者',
            getgift: '得獎',
            gettime: '獲得時間',
            rules: '活動規則',
            s1: `<p>1.活動時間：2019年9月15日-2019年11月15日（在活動時間內，活動金額每日抽取完則活動自動截止）；</p>
 <p>2.活動規則：使用者在活動期間參與活動，可透過本人參與秒合約（下單10手）獲得一次抽獎機會；推薦好友（每直推10人）獲得一次抽獎機會。 </p>
 <p>3.如果遇到任何問題或疑問，歡迎隨時向TUEEX客服提交工單。 </p>
 <p>4.本次活動一切解釋權歸TUEEX所有。 </p>`,
            popup: {
                congratulations: '恭喜獲得',
                unfortunately: '很遺憾',
                i1: '獎品已發放，請到【資產】查看',
                i2: '與大獎插肩而過',
                i3: '沒關係，堅持總有收穫',
                know: '知道了'
            }
        },
    },
    nav: {
        n1: "首頁",
        n2: "行情",
        n3: "交易",
        n4: "OTC",
        n5: "資產",

        back: "返回"
    },
    article: {
        notice: "官方公告",
        help: "新手指導"
    },
    market: {
        fav: "自選"
    },
    trade: {
        t1: '幣幣',
        buyIn: '買進',
        sellOut: '賣出',
        number: '數量',
        use: '餘額',
        tradeAmount: '交易額',
        entrustNow: '當前委託',
        entrustHistory: '歷史成交',
        all: '全部',
        entrustPrice: '委託單價',
        entrustNum: '委託數量',
        entrustAmount: '委託額',
        successDeal: '已成交',
        someDeal: '部分成交',
        failedDeal: '未成交',
        dealPercent: '成交比例',
        buy: '買',
        sell: '賣',
        withdrawal: '撤單',
        add: '新增',
        cancelChoice: '取消自選',
        sec: {
            orderHistory: '下單歷史',
            high: '高',
            low: '低',
            order: '訂單持倉',
            buyUp: '買漲',
            buyDown: '買跌',
            rise: '漲',
            fall: '跌',
            id: "訂單編號",
            investAmount: '金額',
            openType: '開倉類型',
            openDate: '開倉週期',
            openNum: '開倉數量',
            openTime: '開倉時間',
            countdown: '倒數計時',
            profit: '盈虧率(%)',
            nowPrice: '當價',
            buyPrice: '購買時價',
            payPrice: '下單價格',
            expected: '預計損益',
            time: '時間',
            get: '盈利率',
            tradeNum: '交易數量',
            timeLong: '時長',
            myFund: '我的資金',
            more: '更多',
            kl: 'K線',
            plate: '盤口',
            buyplate: '買盤',
            sellplate: '賣盤',
            dealDetails: '成交明細',
            direction: '方向',
            income: '收益',
            lastPrice: '最終價格'
        },
        summary: {
            issueTime: '發行時間',
            issueTotal: '發行總量',
            whitepaper: '白皮書',
            network: '官網',
            block: '區塊查詢',
            introduction: '簡介'
        },
        popup: {
            fundpassword: '資金密碼',
            p1: '請輸入您的資金密碼',
            user: '使用者授權',
            warrant: '授權',
        },
        dropMenus: {
            allCoin: '所有幣種',
            allType: '所有類型',
            allTime: '所有時間',
            three: '三天內',
            week: '一週內'
        },
        canvas: {
            h: '小時', // 等於一小時(hour)
            h2: '小時', // 大於一小時(hours)
            day: '天',
            week: '週',
            times: '分時',
            min: '分',
            noIntroduction: '暫無簡介'
        },
    },
    otc: {
        index: {
            limitAmount: '限額',
            pay: '付款',
            payWays: '付款方式',
            reset: '重置',
            filter: '篩選',
            alipay: '支付寶',
            wepay: '微信',
            bankpay: '銀行卡',
            rmb: '人民幣(CNY)',
            dollar: '美元(USD)',
            supportPay: "請新增商家支援的收款方式"
        },
        popup: {
            playAd: '發起廣告',
            setpayInfo: '設定收/付款資訊',
            apply: '申請成為商家',
            myAd: '我的廣告',
            seeHistory: '查看歷史訂單',
            success: '提交成功',
            ok: '好的',
            totalAmount: '總額'
        },
        detail: {
            price: '單價',
            sum: '總價',
            payWays: '付款方式',
            payTime: '付款期限',
            min: '分鐘',
            s1: `交易提醒：<br>
        1.交易前請詳細了解商家的詳細交易資訊<br>
        2.請透過平台進行溝通約定，並儲存相關聊天記錄<br>
        3.如遇交易糾紛，請透過「申述」來解決問題`,
            howMuchBuy: '您想買多少？ ',
            howMuchSell: '您想賣多少？ ',
            i1: '請輸入購買金額',
            i2: '請輸入購買數量',
            i3: '請輸入出售金額',
            i4: '請輸入出售數量',
        },
        order: {
            tradeOrder: '交易訂單',
            cancelOrder: '取消訂單',
            pending: '進行中',
            obtained: '下架',
            remove: '已下架',
            completed: '已完成',
            loading: '載入中...',
            finish: '全部載入完了...',
            lave: '剩餘',
            state: '申述',
            p1: '已下單',
            p2: '已取消',
            p3: '已付款',
            p4: '已放幣',
            p5: '已評論',
            p6: '待付款',
            call: '聯絡對方',
            buyer: '買家',
            buyerNick: '買家暱稱',
            seller: '賣家',
            orderNum: '訂單編號',
            sellerNick: '賣家暱稱',
            sellerName: '賣家實名',
            payee: '收款帳號',
            qrCode: '二維碼',
            changePay: '切換付款方式',
            payType: '付款類型',
            payAccount: '支付帳號',
            mark: '備註碼',
            paytime: '下單時間',
            tradeTotal: '交易總額',
            i1: '訂單已取消，無法查看付款資訊',
            i2: '未付款，訂單將自動取消',
            i3: '已付款,馬上提醒賣家出貨。 ',
            i4: '對方已付款，等待您給對方放行',
            i5: '請輸入想說的話',
            t1: '在轉帳過程中請勿備註BTC、USDT、TUEEX等訊息，防止匯款被攔截、銀行卡凍結等問題',
            t2: '如買家已向您轉帳付款，請務必點擊“立刻放行”，對您的平均放行時間有影響。進一步可以增強對方對您的信任。 '
        },
    },
    assets: {
        widthdraw: {
            notEth: "請確認您輸入的位址為Erc20位址"
        },
        index: {
            n1: '儲值',
            n2: '提現',
            n3: '轉帳',
            n4: '星球計畫',
            contract_account: '合約帳戶',
            otc_account: 'OTC帳戶',
            normal: '正常',
            direct_push: '直推',
            person: '人',
            promotion_people: '我的推廣(人)',
            accumulative_commission: '累積佣金',
            my_promotional_turnover: '我的推廣交易金額',
            my_promotional_turnover_last_week: '上週我的推廣交易額',
            commission_detail: '佣金明細',
            my_inks: '我的連結',
            invitation_code: '邀請碼：',
            copy_link: '複製連結',
            a1: '幣幣帳戶',
            a2: '秒合約',
            a3: '法幣帳戶',
            coin: '幣幣',
            contract: '合約',
            legalcurrency: '法幣',
            allAssets: '總資產折合',
            user: '個人中心',
            s1: '隱藏為0的資產',
            freeze: '凍結',
            Tokens: '代幣',
            trial_fee: '體驗金',
            experience_gold_freeze: '體驗金凍結',
            equivalent: '折合',
            freeResult: '釋放記錄',
        },
        personal: {
            share: '分享',
            myInvite: '我的邀請碼',
            kyc: '實名認證',
            myteam: '我的團隊',
            safe: '安全中心',
            payAccount: '支付帳戶',
            message: '站內信',
            turnWorklist: '提交工單',
            chooseRate: '選擇匯率',
            changeLang: '設定語言',
            my_assets: "我的資產",
            exchange_binding: "交易所API綁定",
            my_promotion: "我的推廣",
            setting: '設定',
            topic_switching: '主題切換',
            write_off: '註銷',
            identity_authentication: '身分認證'
        },
        lockcoin: {
            i1: '單筆秒合約最低完成數量：',
            i2: '今日秒合約有效完成次數：',
            i3: '全部完成後預計釋放：',
            go: '去完成',
            notLock: '目前我沒有鎖定任何',
            hasLock: '目前我還可以鎖定',
            tips: "請確認幣幣帳戶TUE充足",
            lock: '鎖定',
            hasFreed: '已釋放',
            hasBoom: '已銷毀',
            lockTime: '鎖定時間',
            description: '說明：',
            lockTotal: '鎖倉總額：',
            hasAdd: '還可加倉：',
            chooseNum: '選擇增加鎖定',
            workCompleted: '任務完成',
            completedTime: '完成時間',
            free: '釋放',
            destroy: '銷毀',
            rules: `<p>用戶買了平台幣存入星球計畫進行鎖倉，可存放數量：500TUE、1000TUE、2000TUE、3000TUE、4000TUE、5000TUE、6000TUE、7000TUE、8000TUE、9000TUEUE、20 >
 <p>一個帳戶最少存入500TUE，最多存入20000 TUE。開啟星球計畫過後，交易所這邊會贈送開啟星球計畫數量50%的現貨，贈送的現貨隨時二級市場買賣交易。 </p>
 <p>例如用戶正常買10000TUE存入星球計劃，交易所就會贈送5000TUE現貨，星球計劃鎖倉週期是100天，如果加速釋放鎖倉週期就是50天。 </p>
 <p>釋放細則：</p>
 <p>1：每天交易5手秒合約釋放1%（週期為每天凌晨00：00點-第二天00:00）。 </p>
 <p>2：每天交易10手秒合約釋放2%（週期為每天凌晨00：00點-第二天00:00）。 </p>
 <p>交易的基數是每手不小於開啟星球計畫的1%例如：</p>
 <p>1：5000TUE開啟星球計劃，每手秒合約的下單金額不能低於50TUE。 </p>
 <p>2：10000TUE開啟星球計劃，每手秒合約的下單金額不能低於100TUE。 </p>
 <p>TUE秒合約可選擇的交易基數：5TUE、10TUE、20TUE、30TUE、40TUE、50TUE、60TUE、70TUE、80TUE、90TUE、100TUE、200TUE。 </p>
 <p>交易參考的標的：BTC、ETH、LTC、BCH、 EOS、XRP。 </p>
 `
        },
        invite: {
            give: '註冊即送',
            t1: '長按儲存，辨識二維碼註冊',
            t2: '好友完成實名認證，推薦人獲USDT獎勵',
            yourInvite: '您的邀請碼',
            copyInvite: '複製邀請連結',
        },
        kyc: {
            needC1: "此操作需要完成C1認證",
            needC2: "此操作需要完成C2認證",
            c1: 'C1認證',
            c2: 'C2認證',
            country: '國家',
            documentType: '證件類型',
            l1: '請輸入姓氏',
            f1: '請輸入名字',
            documentNum: '證件號碼',
            n1: '請輸入證件號碼',
            china: '中國',
            usa: '美國',
            idCard: '身分證',
            passport: '護照',
            t1: '請拍攝並上傳您的證件照（最大8M）',
            t2: '圖片大小最高不超過8M，請先裁剪後再上傳',
            t3: 'KYC認證提交成功',
            t4: '請上證件資料',
            face: '點選上傳證件正面照',
            back: '點選上傳證件反面照',
            hand: '點選上傳手持證件照 + TUEEX紙條',
            p1: '現在您未進行C1認證，不可以進行儲值交易。 ',
            p2: '請進行 C1認證，以獲得更多使用者權限！ ',
            p3: '現在您可以進行儲值交易，但完善證件資訊後才可進行提幣。 ',
            p4: '您已進行C1認證，請完善證件資訊以取得更多使用者權限！ ',
            p5: '證件照與填寫證件號碼不符',
            p6: '您已進行C1、C2認證，已解鎖所有使用者權限！ ',
            評論: '【認證審核中】',
            p7: '請耐心等待，<br/>審核會在24小時之內完成',
            sC1: '【C1認證已完成】',
            fC1: '【C1認證失敗】',
            fC2: '【C2認證失敗】',
            wait: '【待審核】',
            success: '【已通過】',
            failed: '【認證失敗】'
        },
        myteam: {
            teamNum: '隊伍人數：',
            directPush: '直推人數：',
            mytitle: '我的頭銜',
            joinNum: '參與人數',
            rule1: '秒合約規則',
            rule2: '星球計畫規則',
            i1: '秒合約獎勵明細',
            i2: '星球計畫獎勵明細',
            o1: '旗下地主人數',
            o2: '旗下富農人數',
            o3: '旗下中農人數',
            allNum: '總參與人數',
            yesIncome: '昨日總收益',
            seeAll: '全部',
            seeDetail: '查看明細',
            v0: '普通節點',
            v1: '初級節點',
            v2: '中級節點',
            v3: '高階節點',
            v4: '超級節點',
            l0: '一般使用者',
            l1: '中農',
            l2: '富農',
            l3: '地主'
        },
        payAccount: {
            addType: '新增類型',
            addPayAccount: '新增收/付款方式',
            chooseBank: '選擇銀行',
            bank: "銀行",
            bankName: '開戶行',
            bankUser: '開戶名稱（姓名/單位名稱）',
            bankCard: '銀行卡號',
            b1: '請輸入開戶行名稱',
            b2: '請輸入開戶姓名或開戶單位名稱',
            b3: '請輸入銀行卡號',
            realname: '真實姓名',
            alipayAccount: '支付寶帳號',
            wepayAccount: '微信帳號',
            paypalAccount: 'PayPal帳號',
            uploadCode: '上傳二維碼（可選）',
            p1: '請輸入真實姓名',
            p2: '請輸入支付寶帳號',
            p3: '請輸入微信帳號',
            p4: '請輸入PayPal帳號',
            defaultBank: '中國銀行',
            z1: '["銀行卡","支付寶","微信","PayPal"]',
            z2: '["中國銀行","中國工商銀行","中國農業銀行","中國建設銀行","交通銀行","中信銀行","中國光大銀行","中國民生銀行","招商銀行","興業銀行","平安銀行","中國郵政儲蓄銀行","浙商銀行"]',
        },
        coinaddr: {
            addCoinAddr: '新增提幣地址',
            mark: '備註',
            p1: '請輸入備註',
            t1: '刪除提幣地址',
            t2: '確認刪除該提幣地址嗎？ ',
            t3: '刪除提幣地址成功！ ',
        },
        worklist: {
            myworklist: '我的工單',
            newworklist: '新工單',
            noworklist: '目前您還沒有工單記錄',
            mine: '我：',
            service: '客服：',
            reply: '回覆',
            open: '展開全部回覆',
            close: '收起全部回覆',
            wait: '待處理',
            doing: '處理中',
            question: '選擇您的問題'
        },
        message: {
            unread: '未讀',
            readed: '已讀'
        },
        safe: {
            bindEmail: '綁定信箱',
            bindMobile: '綁定手機',
            modify: '進入修改',
            notBind: '未綁定',
        }
    },
    form: {// 表單類
        email: '電子郵件',
        mobile: '手機號碼',
        username: '手機或電子郵件',
        password: '登錄密碼',
        twice_password: '再輸入密碼',
        newPassword: '新登錄密碼',
        verifyCode: '驗證碼',
        loginAccount: '登錄帳號',
        submit: '提交',
        needMobile: "還未綁定手機，請先綁定手機",
        needEmail: "還未綁定信箱，請先綁定信箱",
        needMobileOrEmail: "您需要先同時綁定信箱和手機",

        signup: {
            submit: '註冊',
            mobile: '手機註冊',
            email: '信箱註冊',
            recCode: '推薦碼(非必填)',
            goSignin: '登錄帳號',
            bindEmail: '新增郵箱綁定',
            bindMobile: '新增手機綁定',
            changePsw: '修改登錄密碼',
            changeAssetsPsw: '修改資金密碼',
            emailCode: '郵箱驗證碼',
            e1: '請輸入您的電子郵件信箱',
            e2: '請輸入信箱驗證碼',
            m1: '請輸入您的手機號碼',
            m2: '請輸入簡訊驗證碼',
            registration_represents_consent: '註冊即代表同意',
            user_agreement: '《使用者協議》'
        },
        signin: {
            submit: '登錄',
            register: '馬上註冊',
            forget: '忘記密碼'
        },
        forgot: {
            resetPsw: '重設密碼',
        },
        otc: {
            applyM: {
                applyMerchant: '申請商家',
                merchantName: '商家名稱',
                t1: '請輸入您的商家名稱',
                applyReason: '申請原因',
                t2: '請輸入申請理由（不超過140字）',
            },
            initiateAd: {
                tradeMode: '選擇交易模式',
                legalcurrency: '選擇法幣',
                payWays: '選擇付款方式',
                reciveWays: '選擇收款方式',
                coin: '選擇幣種',
                setCoinPrice: '設定幣種價格',
                inputAmount: '輸入購買金額',
                minAmount: '最小額度',
                maxAmount: '最大額度',
                remarks: '商家留言',
                t1: '請輸入留言（不超過140字）',
                goChoose: '去選擇',
                limitAmount: '交易限額',
                over: '交易金額超出範圍',
                i1: '請輸入幣種單價'
            },
            state: {
                stateType: '申述類型',
                stateReason: '申述原因',
                i1: '請輸入你的訂單編號',
                i2: '請輸入申述原因（不超過140字）',
                evidence: '證明資料上傳(最多4張)',
                stateSuc: '申述成功',
                stateFailed: '申述失敗'
            }
        },
        assets: {
            cbResult: '儲值記錄',
            cbAddr: '儲值地址',
            tbResult: '提現記錄',
            zzResult: '轉帳記錄',
            transaction_records: '交易記錄',
            tbAddr: '提現地址',
            txNum: '提現數量',
            chooseAddr: '選擇地址',
            tbNum: '提幣數量',
            fee: '手續費',
            arrival: '實際到帳',
            tradePsw: '資金密碼',
            max: '最大可提',
            sms: '簡訊驗證碼',
            email: '信箱驗證碼',
            transfer: '劃轉',
            from: '從',
            to: '到',
            transferNum: '劃出數量',
            alltransfer: '全部轉出',
            cantransfer: '可轉',
            saveCode: '長按二維碼儲存',
            copyAddr: '複製位址',
            t1: '溫馨提示：',
            t2: '• 最小充幣數量為：',
            t3: '請勿向上述地址充值任何非',
            t4: '資產。否則將不可找回。 ',
            p1: '請用手機掃描二維碼',
            p2: '請輸入劃出數量',
            p3: '請輸入提幣地址',
            p4: '請輸入提幣數量',
            p5: '請輸入資金密碼',
            p6: '請輸入驗證碼',
            s1: `溫馨提示：<br>
        • 最小充幣數量為：{num}個。請勿向上述地址充值任何非資產。否則將不可找回。
        `,
            s2: `溫馨提示：<br>
        • 最小提幣數量為：<b class="sellcolor">{lowerLimitOut} {symbol}</b>。 <br>
        • 為保障資金安全，我們會對提幣進行人工審核，請耐心等待；<br>
        • 您可以透過區塊瀏覽器查詢提幣狀態，如長時間沒有到賬，請聯絡客服人工解決；<br>
        • 請務必確認電腦及瀏覽器安全，防止資訊被竄改或洩露，我們推薦您使用Google瀏覽器。 `,
            s3: ``
        },
        account: {
            oldpwd: '原密碼',
            o1: '請輸入原登錄密碼',
            o2: '請輸入原資金密碼',
            newpwd: '新密碼',
            n1: '請輸入新登錄密碼',
            n2: '請輸入新的資金密碼',
            repwd: '再輸入一次密碼',
            r1: '請再輸入新登錄密碼',
            r2: '請再輸入新的資金密碼',
            repwd: '再次輸入',
            username: '姓名',
            account: '帳號',
            firstname: '名',
            lastname: '姓',
            please_enter_your_real_name: '請輸入您的真實姓名',
            card_id: '身份卡ID',
            please_enter_your_number: '請輸入身分證號',
            id_photo: '身分證照片',
            not_uploaded: '未上傳',
            save: '儲存',
            please_complete: '請完善資訊或重新選擇圖片'
        },
        uploadImg: {
            chooseImg: '選擇圖片',
            t1: '圖片類型必須是.gif,jpeg,jpg,png,bmp中的一種',
            t2: '上傳圖片不能超過5M'
        }
    },
    message: { // 提示類別訊息
        searchCoin: '搜尋貨幣',
        home: {
            top: '可選'
        },
        sign: {

        },
        sms: {
            s1: '驗證碼發送成功，請仔細檢查'
        },
        invite: {
            success: "接收成功！",
        },
        copy: {
            success: "複製成功！",
            failed: "複製失敗，請選擇手動複製！"
        },
        nodata: '還沒有資料',
        pending: '[申請正在審核中]',
        success: '[申請成功]',
        failed: '[應用程式失敗]',
        upload: '圖片上傳中，請稍候...',
        emailSuc: '郵箱驗證碼發送成功，請檢查！ ',
        otc: {
            success: '申請成功，請等待審核',
            failed: '申請原因已超出限制，請刪除',
            i1: '您的資料正在審核中。 <br/>請耐心等待，我們將在三到五個工作天內與您聯繫',
            i2: '申請商戶失敗。 <br/>請「重新提交」您的申請',
            i3: '您發布的廣告正在審核中。 <br/>請耐心等待，我們將在三到五個工作天內與您聯繫',
            min: '最小金額不能小於0',
            max: '最大金額不能小於0',
            playSuc: '發布成功',
            require: '請填寫必填欄位',
            confirmOrder: '確認訂單？ ',
            confirmPay: '您確定付款已完成嗎？ ',
            cancelOrder: '您確定取消此訂單嗎？ ',
            p1: '我已釋放，請及時查看餘額',
            p2: '抱歉，我已取消訂單',
            p3: '我已付款，請及時釋放',
            verifyCoin: '確認放幣嗎？ ',
            successCoin: '發幣成功',
            removeSuc: '刪除成功',
            over: '超出交易範圍'
        },
        assets: {
            t1: '儲值申請已發起，等待管理員審核',
            t2: '暫時不允許類似的錢包轉帳',
            t3: '轉帳資金必須大於0',
            t4: '轉帳資金超出上限',
            t5: '申請的貨幣數量不能小於',
            t6: '請輸入正確的資金密碼',
            t7: '提幣申請已發送，等待管理員審核',
            t8: '添加提幣地址成功！ ',
            t9: '請填寫輸入項',
            t10: '工單提交成功！ ',
            TransferSuc: '資產轉移成功！ ',
            addFailed: '新增失敗',
            hasAdd: '您已新增此付款方式',
            p1: '預設付款方式無法刪除',
            p2: '刪除付款方式',
            p3: '您確定刪除此付款方式嗎？ ',
            deleteSuc: '刪除成功',
            deleteFailed: '刪除失敗',
        }
    },
    button: { // button
        uploadSure: '可以上傳',
        trade: {
            gotrade: '開始交易'
        },
        otc: {
            buy: '买',
            sell: '賣',
            sure: '確認',
            cancel: '取消',
            buyNow: '立即購買',
            buySure: '確認購買',
            payNow: '立即付款',
            sellNow: '立即出售',
            cancelOrder: '取消訂單',
            freedcoin: '立即釋放硬幣',
            Send: '發送'
        },
        assets: {
            TransferSure: '確認轉移',
            sendSms: '發送驗證碼',
            hasSend: '已發送',
            reSend: '重新發送',
            sure: '確定',
            submit: '提交審核',
            logout: '退出登錄',
            toC1: '執行C1認證',
            toC2: '執行C2認證',
            todo: '完整的證書資訊',
            redo: '重新驗證',
            redoC2: '重新驗證C2'
        }
    },
    validate: { // 驗證類別訊息
        public: {
            notEmpty: '人機驗證初始化未完成，即將刷新~',
            formatError: '格式錯誤',
            geetest: '人機驗證失敗',
            smsError: '請輸入4位元驗證碼',
            inviteCode: '請輸入7位元邀請碼',
            passwordError: "請輸入6-20位元大小寫字母加數字的密碼",
            oldPswError: '原密碼輸入錯誤，需包含英文、數字',
            newPswError: '新密碼輸入有誤，需包含英文、數字',
            notSame: '兩次密碼輸入不一致',
            signin: '請先登錄',
            emailError: '信箱格式有誤',
            mobileError: '手機號碼格式有誤',
            notNull: '輸入項目不能為空',
            bindEmailSuc: '綁定信箱成功！ ',
            bindMobileSuc: '綁定手機成功！ ',
            changePswSuc: '登錄密碼修改成功！ ',
            changeAssetsPswSuc: '資金密碼修改成功！ ',
            changeAssetsPswToast: '第一次設定資金密碼時原密碼為登錄密碼',
            twicePassword: '兩次密碼不一致'
        },
        signup: {
            usernameError: '請輸入正確的信箱或電話號碼',
            success: "註冊成功！",
        },
        signin: {
            mobileError: "請輸入正確的電話號碼",
            emailError: '請輸入正確的信箱',
            requireError: '請錄入必填項',
            success: '登錄成功！ ',
            fail: '登錄資訊有誤或人機驗證失敗'
        },
        forget: {
            success: "重置密碼成功！",
            fail: "重設密碼失敗，請稍後重試"
        },
        smsStatus: {
            發送: "發送驗證碼",
            sendMail: "發送驗證郵件",
            sending: "已發送",
            resend: "重新發送",
            sucess: '驗證碼發送成功，請注意查收',
            error: "驗證碼發送失敗！"
        },
        robot: "人機驗證初始化失敗",
        success: "操作成功！",
        fail: "操作失敗",
        suc: "成功",
        err: "失敗",
        sure: "確認",
        needAll: "請輸入所有必填項目",
        logout: {
            title: '使用者登出',
            content: '是否確認登出？ '
        },
        trade: {
            success: '掛單成功',
            fail: '掛單失敗',
            noUse: '無可用 {coin}',

            minBuy: '最小可買{num} {coin}',
            maxBuy: '最大可買{num} {coin}',
            buyAmount: '請輸入購買 {coin} 數量',
            buyPrice: '請輸入購買 {coin} 單價',

            maxSell: '最大可賣{num} {coin}',
            minSell: '最小可賣{num} {coin}',
            sellAmount: '請輸入出售 {coin} 數量',
            sellPrice: '請輸入出售 {coin} 單價',

            formatError: '格式錯誤',
            overflow: '您掛單的單價和當前成交價格差距超過{count}%，確認是否以當前價格提交？ ',
            messagePop: {
                title: '提示'
            },
            inputSuccess: 'Google Authenticator驗證成功，有效時間{time}分鐘',
            coin: {
                funderror: '資金密碼輸入有誤',
                success: '授權成功',
                orderSuc: '下單成功',
                withdrawalSuc: '撤單申請已提交成功',
                betNum: '請選擇下單數量',
                betSuccess: '下單成功',
                favSuc: '新增自選成功！ ',
                favFailed: '取消自選成功'
            }
        },
        assets: {
            t1: '確認退出登錄嗎？ ',
            t2: '保存頭像成功！ '
        }
    },
    rule: {
        s1: `<p>1、選擇交易品種（BTC、ETH、LTC、BCH、 EOS、XRP）。 </p>
            <p>2、選擇交易時間1分鐘-5分鐘-15分鐘-30分鐘-60分鐘（快速，適宜時間廣，收益快，24小時可交易）。 </p>
            <p>3、設定交易金額20USDT、50 USDT 、100 USDT 、200 USDT 、500 USDT 、1000 USDT 、
            2000 USDT（假設投資100USDT，82%的回報率，若贏利可得82 USDT +100 USDT本金=182 USDT 收入）。設定交易金額5TUE、10 TUE 、20 TUE 、30 TUE 、40 TUE 、50 TUE 、60 TUE 、70 TUE 、80 TUE 、90 TUE 、100 TUE 、200 TUE （假設投資100TUE，82%的回報率，若贏利可得82 TUE +100 TUE本金=182 TUE 收入）。 </p>
            <p>4、選擇交易方向：雙向交易（漲或跌）判斷價格走勢，可點擊買“漲”，也可點擊買“跌”。 </p>
            <p>5、交易到期結算，交易期間一到，自動結算損虧。 </p>`,
        s2: `<p>用戶買了平台幣存入星球計畫進行鎖倉，可存放數量：500TUE、1000TUE、2000TUE、3000TUE、4000TUE、5000TUE、6000TUE、7000TUE、8000TUE、9000TUEUE、FUEUE、UE
            一個帳戶最少存入500TUE，最多存入20000 TUE。開啟星球計畫過後，交易所這邊會贈送開啟星球計畫數量50%的現貨，贈送的現貨隨時二級市場買賣交易。
            例如用戶正常買10000TUE存入星球計劃，交易所就會贈送5000TUE現貨，星球計劃鎖倉週期是100天，如果加速釋放鎖倉週期就是50天。
            釋放細則：</p>
            <p>1：每天交易5手秒合約釋放1%（週期為每天凌晨00：00點-第二天00:00），</p>
            <p>2：每天交易10手秒合約釋放2%（週期為每天凌晨00：00點-第二天00:00）。 </p>
            <p>交易的基數是每手不小於開啟星球計畫的1%例如：</p>
            <p>1：5000TUE開啟星球計劃，每手秒合約的下單金額不能低於50TUE。 </p>
            <p>2：10000TUE開啟星球計劃，每手秒合約的下單金額不能低於100TUE,</p>
            <p>TUE秒合約可選擇的交易基數：5TUE、10TUE、20TUE、30TUE、40TUE、50TUE、60TUE、70TUE、80TUE、90TUE、100TUE、200TUE,
            交易參考的標的：BTC、ETH、LTC、BCH、 EOS、XRP。 </p>`
    },
    other: {
        translate1: '設定',
        translate2: '設定檔設定',
        translate3: '我的',
        translate4: '合約交易',
        translate5: '幣幣交易',
        translate6: '登錄',
        translate7: '可用',
        translate8: '交易額',
        translate9: '做多',
        translate10: '做空',
        translate11: '停損價',
        translate12: '止盈價',
        translate13: '訂單修改',
        translate14: '深度5',
        translate15: '深度10',
        translate16: '深度15',
        translate17: '預設',
        translate18: '買盤',
        translate19: '賣盤',
        translate20: '限價委託',
        translate21: '市場委託',
        translate22: '當前委托',
        translate23: '全部訂單',
        translate24: '持倉價',
        translate25: '止盈價',
        translate26: '成交量',
        translate27: '最新價格',
        translate28: '停損價',
        translate29: '付款方式',
        translate30: '保證金',
        translate31: '手續費',
        translate32: '訂單類型',
        translate33: '平倉',
        translate34: '修改',
        translate35: '自然訂購',
        translate36: '自動跟單',
        translate37: '體驗金',
        translate38: '動態權益',
        translate39: '凍結',
        translate40: '浮動盈虧',
        translate41: '爆倉率',
        translate42: '開始時間',
        translate43: '結束時間',
        translate44: '凍結',
        translate45: '訂單號碼',
        translate46: '槓桿倍數',
        translate47: '持倉',
        translate48: '委託',
        translate49: '成交',
        translate50: '合約交易',
        translate51: '買進',
        translate52: '賣出',
        translate53: '數量',
        translate54: '狀態',
        translate55: '成交額',
        translate56: '委託價',
        translate57: '撤銷',
        translate58: '暫無數據',
        translate59: '幣幣訂單',
        translate60: '歷史委託',
        translate61: '快速買幣',
        translate62: '邀請',
        translate63: '支持',
        translate64: '受歡迎的',
        translate65: '成功',
        translate66: '失敗',
        translate67: '審核中',
        translate68: '地址',
        translate69: '幣種',
        translate70: '時間',
        translate71: '提現幣種',
        translate72: '轉帳幣種',
        translate73: '說明',
        translate74: '請在Ether鏈進行USDT-ERC20的充值;',
        translate75: '儲值錯誤,由使用者自己承擔;',
        translate76: '儲值到帳時間約1分鐘;',
        translate77: '帳號選擇',
        translate78: '已完成',
        translate79: '資金',
        translate80: '限價',
        translate81: '市價',
        translate82: '漲跌幅',
        translate83: '將身分證正反面拍清楚',
        translate84: '確保您處於光線充足的環境中，並拍攝護照正面和背面的照片。',
        translate85: '拍照',
        translate86: '實名認證',
        translate87: '止盈',
        translate88: '停損',
        translate89: '下一步',
        translate90: '載入中',
        translate91: '點擊載入更多',
        translate92: '全部載入完成',
        translate93: '平臺信息',
        translate94: '郵箱',
        translate95: '收款方式管理',
        translate96: '未設定',
        translate97: '請設定您的收款方式，請務必使用本人的身分帳號',
        translate98: '銀行卡帳號',
        translate99: '支付寶帳號',
        translate100: '微信帳號',
        translate101: 'SWIFT國際匯款',
        translate102: '未綁定',
        translate103: '姓名',
        translate104: '資金密碼',
        translate105: '請輸入付款寶號',
        translate106: '請輸入款項密碼',
        translate107: '綁定支付寶帳號',
        translate108: '收款二維碼',
        translate109: '儲存',
        translate110: '儲存成功',
        translate111: '開戶銀行',
        translate112: '開戶支行',
        translate113: '請選擇',
        translate114: '請輸入開戶支行',
        translate115: '請輸入銀行卡號',
        translate116: '確認卡號',
        translate117: '請再輸入銀行卡號',
        translate118: '取消',
        translate119: '綁定銀行卡帳號',
        translate120: '收款地址',
        translate121: '請輸入微信帳號',
        translate122: '綁定微信帳號',
        translate123: '提交成功',
        translate124: '請輸入賣出量',
        translate125: '請輸入買入量',
        translate126: '交易額不能高於',
        translate127: '買入數量不能高於',
        translate128: '請輸入賣出價格',
        translate129: '最多能賣',
        translate130: '個',
        translate131: '撤單成功',
        translate132: '修改成功',
        translate133: '修改成功',
        translate134: '價格變動',
        translate135: '最高',
        translate136: '最低',
        translate137: '請輸入使用者名稱',
        translate138: '請輸入密碼',
        translate139: '或繼續',
        translate140: '登錄Web3',
        translate141: '輸入你的使用者名稱和密碼',
        translate142: '請再輸入密碼',
        translate143: '建立一個帳號',
        translate144: '註冊',
        translate145: '已經有帳號了',
        translate146: '點擊繼續，即表示您同意我們的',
        translate147: '服務條款',
        translate148: '隱私權政策',
        translate149: '和',
        translate150: '註冊',
        translate151: '沒有帳號?',
        translate152: '連接錢包',
        translate153: '兩次密碼不一致',
        translate154: '請儲值USDT',
        translate155: '全部',
        translate156: '請輸入數量',
        translate157: '提交成功，等待審核！ ',
        translate158: '還在審核中，請耐心等待',
        translate159: '已經認證過了，不能重複認證',
        translate160: '儲值成功',
        translate161: '儲值USDT-ERC20',
        translate162: '輸入金額',
        translate163: '獲取儲值地址',
        translate164: '為保障資金快速安全到賬，我們將給你一組隨機尾數',
        translate165: '請按下方金額轉賬，否則無法到賬，後果自行承擔',
        translate166: '儲值地址',
        translate167: '我已複製好訊息',
        translate168: '儲值注意',
        translate169: '複製成功!',
        translate170: '複製失敗!',
        translate171: '儲值數量不能為空',
        translate172: '沒有更多了',
        translate173: '載入中...',
        translate174: '請輸入大於0的整數',
        translate175: '分',
        translate176: '秒',
        translate177: '倒計時',
    }
}
export default lang;