const lang = {
    // home
    home: {
        notice: '',
        i1: 'Official announcement',
        i2: 'Newbie guide',
        i3: 'Contract',
        i4: 'APP download',
        i5: 'Online Customer Service',
        sm1: 'Growth list',
        sm2: 'Lost list',
        sm3: 'New currency list',
        l1: 'Trading pair',
        l2: 'Price',
        l3: 'Increase or decrease',
        sl: 'quantity',
        download: {
            android: 'Android download',
            IOS: 'IOS download'
        },
        turntable: {
            lucky: 'Lucky Wheel',
            winlist: 'Winner list',
            myprize: 'my prize',
            notimes: 'Insufficient number of draws',
            thanks: 'Thank you for participating',
            thankJoin: 'Thank you<br/>for participating',
            start: 'Now<br/>Lottery',
            your: 'You currently have',
            yourtimes: 'Lottery chances',
            gettimes: 'Get lottery opportunities',
            recommend: 'Recommend friends',
            h1: 'Operate (place an order) 10 lots,<br/>Get 1 lucky draw opportunity',
            h2: 'Each 10 direct referrals (KYC C2)<br/>get 1 lucky draw opportunity',
            o1: 'Place an order immediately',
            o2: 'Invite immediately',
            user: 'user',
            getgift: 'winner',
            gettime: 'get time',
            rules: 'activity rules',
            s1: `<p>1. Activity time: September 15, 2019 - November 15, 2019 (during the activity time, the activity will automatically end when the activity amount is drawn every day);</p>
        <p>2. Activity rules: Users who participate in the activity during the activity can get a lucky draw opportunity by participating in the second contract (10 orders placed); they can get a lucky draw opportunity by recommending friends (for every 10 direct referrals). </p>
        <p>3. If you encounter any problems or questions, please feel free to submit a work order to TUEEX customer service at any time. </p>
        <p>4. All interpretation rights for this event belong to TUEEX. </p>`,
            popup: {
                congratulations: 'Congratulations',
                unfortunately: 'unfortunately',
                i1: 'The prize has been distributed, please go to [Assets] to check',
                i2: 'pass by the big prize',
                i3: 'It doesn t matter, persistence will always pay off',
                know: 'got it'
            }
        },
    },
    nav: {
        n1: "Homepage",
        n2: "Quotes",
        n3: "Transaction",
        n4: "OTC",
        n5: "Asset",

        back: "return"
    },
    article: {
        notice: "official announcement",
        help: "Newbie Guide"
    },
    market: {
        fav: "your choice"
    },
    trade: {
        t1: 'bibi',
        buyIn: 'Buy',
        sellOut: 'Sell',
        number: 'Quantity',
        use: 'balance',
        tradeAmount: 'Trade amount',
        entrustNow: 'Current entrust',
        entrustHistory: 'Historical transaction',
        all: 'all',
        entrustPrice: 'Entrust unit price',
        entrustNum: 'entrust number',
        entrustAmount: 'entrust amount',
        successDeal: 'Dealed',
        someDeal: 'Partial deal',
        failedDeal: 'Undelivered',
        dealPercent: 'deal ratio',
        buy: 'buy',
        sell: 'sell',
        withdrawal: 'withdraw order',
        add: 'New',
        cancelChoice: 'Cancel choice',
        sec: {
            orderHistory: 'Order history',
            high: 'high',
            low: 'low',
            order: 'Order position',
            buyUp: 'buy up',
            buyDown: 'Buy down',
            rise: 'rise',
            fall: 'fall',
            id: "Order number",
            investAmount: 'amount',
            openType: 'Opening type',
            openDate: 'Opening period',
            openNum: 'Open position quantity',
            openTime: 'position opening time',
            countdown: 'Countdown',
            profit: 'Profit and loss rate (%)',
            nowPrice: 'current price',
            buyPrice: 'Purchase price',
            payPrice: 'Order price',
            expected: 'Estimated profit and loss',
            time: 'time',
            get: 'profit rate',
            tradeNum: 'Transaction quantity',
            timeLong: 'duration',
            myFund: 'my funds',
            more: 'more',
            kl: 'K line',
            plate: 'handicap',
            buyplate: 'buy',
            sellplate: 'Sell plate',
            dealDetails: 'Deal details',
            direction: 'direction',
            income: 'income',
            lastPrice: 'final price'
        },
        summary: {
            issueTime: 'Issue time',
            issueTotal: 'Total issue amount',
            whitepaper: 'white paper',
            network: 'official website',
            block: 'block query',
            introduction: 'Introduction'
        },
        popup: {
            fundpassword: 'Fund password',
            p1: 'Please enter your fund password',
            user: 'User authorization',
            warrant: 'authorization',
        },
        dropMenus: {
            allCoin: 'All currencies',
            allType: 'all types',
            allTime: 'all time',
            three: 'within three days',
            week: 'within one week'
        },
        canvas: {
            h: 'hour', // equal to one hour (hour)
            h2: 'hour', // greater than one hour (hours)
            day: 'day',
            week: 'week',
            times: 'timeshare',
            min: 'minute',
            noIntroduction: 'No introduction'
        },
    },
    otc: {
        index: {
            limitAmount: 'limit',
            pay: 'payment',
            payWays: 'Payment method',
            reset: 'reset',
            filter: 'filter',
            alipay: 'Alipay',
            wepay: 'WeChat',
            bankpay: 'bank card',
            rmb: 'RMB(CNY)',
            dollar: 'USD',
            supportPay: "Please add a payment method supported by the merchant"
        },
        popup: {
            playAd: 'Launch advertising',
            setpayInfo: 'Set payment/receipt information',
            apply: 'Apply to become a merchant',
            myAd: 'My Ad',
            seeHistory: 'View historical orders',
            success: 'Submission successful',
            ok: 'OK',
            totalAmount: 'Total amount'
        },
        detail: {
            price: 'unit price',
            sum: 'total price',
            payWays: 'payment method',
            payTime: 'payment period',
            min: 'minute',
            s1: `Transaction Reminder:<br>
        1. Please learn more about the merchant’s detailed transaction information before trading<br>
        2. Please communicate and agree through the platform and save relevant chat records<br>
        3. If you encounter transaction disputes, please resolve the problem through "Representation`,
            howMuchBuy: 'How much do you want to buy? ',
            howMuchSell: 'How much do you want to sell? ',
            i1: 'Please enter the purchase amount',
            i2: 'Please enter the purchase quantity',
            i3: 'Please enter the sales amount',
            i4: 'Please enter the sales quantity',
        },
        order: {
            tradeOrder: 'Trade order',
            cancelOrder: 'Cancel order',
            pending: 'in progress',
            obtained: 'removed',
            remove: 'Removed',
            completed: 'Completed',
            loading: 'Loading...',
            finish: 'All loaded...',
            lave: 'remainder',
            state: 'Representation',
            p1: 'Order placed',
            p2: 'Cancelled',
            p3: 'Paid',
            p4: 'Coins have been released',
            p5: 'Commented',
            p6: 'Pending payment',
            call: 'Contact the other party',
            buyer: 'buyer',
            buyerNick: 'Buyer Nickname',
            seller: 'seller',
            orderNum: 'Order number',
            sellerNick: 'Seller Nickname',
            sellerName: 'Seller s real name',
            payee: 'Payment account number',
            qrCode: 'QR code',
            changePay: 'Switch payment method',
            payType: 'payment type',
            payAccount: 'payment account',
            mark: 'remark code',
            paytime: 'order time',
            tradeTotal: 'Total transaction amount',
            i1: 'The order has been canceled and payment information cannot be viewed',
            i2: 'If payment is not made, the order will be automatically canceled',
            i3: 'Payment has been made, please remind the seller to ship immediately. ',
            i4: 'The other party has paid and is waiting for you to release the other party',
            i5: 'Please enter what you want to say',
            t1: 'Please do not remark BTC, USDT, TUEEX and other information during the transfer process to prevent problems such as interception of remittances and freezing of bank cards',
            t2: 'If the buyer has transferred payment to you, please be sure to click "Release Immediately", which will affect your average release time. This can further enhance the other partys trust in you. '
        },
    },
    assets: {
        widthdraw: {
            notEth: "Please confirm that the address you entered is an Erc20 address"
        },
        index: {
            n1: 'stored value',
            n2: 'Withdraw',
            n3: 'Transfer',
            n4: 'Planet Project',
            contract_account: 'Contract account',
            otc_account: 'OTC account',
            normal: 'normal',
            direct_push: 'direct push',
            person: 'person',
            promotion_people: 'My promotion (people)',
            accumulative_commission: 'accumulative commission',
            my_promotional_turnover: 'My promotional transaction amount',
            my_promotional_turnover_last_week: 'My promotional transaction amount last week',
            commission_detail: 'Commission details',
            my_inks: 'My links',
            invitation_code: 'Invitation code:',
            copy_link: 'Copy link',
            a1: 'Binbi account',
            a2: 'Second Contract',
            a3: 'Fiat currency account',
            coin: 'Coin coin',
            contract: 'contract',
            legalcurrency: 'legal currency',
            allAssets: 'Total assets converted',
            user: 'Personal Center',
            s1: 'Assets hidden as 0',
            freeze: 'freeze',
            Tokens: 'Token',
            trial_fee: 'Trial fee',
            experience_gold_freeze: 'experience gold freeze',
            equivalent: 'converted',
            freeResult: 'Release record',
        },
        personal: {
            share: 'share',
            myInvite: 'My invitation code',
            kyc: 'Real-name authentication',
            myteam: 'my team',
            safe: 'Safety Center',
            payAccount: 'payment account',
            message: 'Site message',
            turnWorklist: 'Submit work order',
            chooseRate: 'Choose exchange rate',
            changeLang: 'Set language',
            my_assets: "My assets",
            exchange_binding: "Exchange API binding",
            my_promotion: "My promotion",
            setting: 'setting',
            topic_switching: 'topic switching',
            write_off: 'Logout',
            identity_authentication: 'Identity authentication'
        },
        lockcoin: {
            i1: 'Minimum completion quantity of a single second contract:',
            i2: 'The number of effective completions of todays second contract:',
            i3: 'Estimated release after all completion:',
            go: 'Go and finish',
            notLock: 'Currently I dont have any locks',
            hasLock: 'Currently I can still lock',
            tips: "Please confirm that the TUE in your Bibi account is sufficient",
            lock: 'lock',
            hasFreed: 'released',
            hasBoom: 'Destroyed',
            lockTime: 'lock time',
            description: 'Description:',
            lockTotal: 'Total amount locked:',
            hasAdd: 'You can also add positions:',
            chooseNum: 'Choose to increase the lock',
            workCompleted: 'Task completed',
            completedTime: 'completion time',
            free: 'release',
            destroy: 'destroy',
            rules: `<p>Users buy platform coins and deposit them into Planet Project for locking. The amount that can be stored is: 500TUE, 1000TUE, 2000TUE, 3000TUE, 4000TUE, 5000TUE, 6000TUE, 7000TUE, 8000TUE, 9000TUE, 20 >
            <p>The minimum deposit into an account is 500 TUE, and the maximum deposit is 20,000 TUE. After the planet project is launched, the exchange will give away 50% of the amount of spot that is used to start the planet project. The gifted spot can be traded in the secondary market at any time. </p>
            <p>For example, if a user normally buys 10,000 TUE and deposits it into the Planet Plan, the exchange will give away 5,000 TUE in spot. The lock-up period of the Planet Plan is 100 days. If the release is accelerated, the lock-up period will be 50 days. </p>
            <p>Release details:</p>
            <p>1: 1% will be released by trading 5 lots per second contract every day (the period is from 00:00 a.m. every day to 00:00 the next day). </p>
            <p>2: 2% will be released by trading 10 lots per second contract every day (the period is from 00:00 a.m. every day to 00:00 the next day). </p>
            <p>The basis of the transaction is that each lot is not less than 1% of the Open Planet Project. For example:</p>
            <p>1: 5000TUE starts the Planet Plan, and the order amount for each second contract cannot be less than 50TUE. </p>
            <p>2: 10,000TUE starts the Planet Plan, and the order amount for each second contract cannot be less than 100TUE. </p>
            <p>Selectable trading bases for TUE second contract: 5TUE, 10TUE, 20TUE, 30TUE, 40TUE, 50TUE, 60TUE, 70TUE, 80TUE, 90TUE, 100TUE, 200TUE. </p>
            <p>Transaction reference targets: BTC, ETH, LTC, BCH, EOS, XRP. </p>
            `
        },
        invite: {
            give: 'Sign up and get it',
            t1: 'Long press to save, identify QR code to register',
            t2: 'Friend completes real-name authentication, and the recommender receives USDT reward',
            yourInvite: 'Your invitation code',
            copyInvite: 'Copy invitation link',
        },
        kyc: {
            needC1: "This operation requires completion of C1 certification",
            needC2: "This operation requires completing C2 certification",
            c1: 'C1 certification',
            c2: 'C2 certification',
            country: 'country',
            documentType: 'Document type',
            l1: 'Please enter your last name',
            f1: 'Please enter your name',
            documentNum: 'Document number',
            n1: 'Please enter the ID number',
            china: 'China',
            usa: 'United States',
            idCard: 'ID card',
            passport: 'Passport',
            t1: 'Please take and upload your ID photo (maximum 8M)',
            t2: 'The maximum image size cannot exceed 8M, please crop it before uploading',
            t3: 'KYC certification submitted successfully',
            t4: 'Please provide identification information',
            face: 'Click to upload a front-facing photo of your ID',
            back: 'Click to upload the photo of the back of your ID',
            hand: 'Click to upload a photo of your handheld ID + TUEEX note',
            p1: 'Now that you have not undergone C1 certification, you cannot conduct stored value transactions. ',
            p2: 'Please perform C1 authentication to obtain more user rights! ',
            p3: 'Now you can perform stored-value transactions, but you can only withdraw money after completing the certificate information. ',
            p4: 'You have carried out C1 certification, please complete the certificate information to obtain more user rights! ',
            p5: 'The ID photo does not match the ID number filled in',
            p6: 'You have undergone C1 and C2 authentication and have unlocked all user rights! ',
            Comment: '[Under certification review]',
            p7: 'Please wait patiently,<br/>the review will be completed within 24 hours',
            sC1: '[C1 certification completed]',
            fC1: '[C1 authentication failed]',
            fC2: '[C2 authentication failed]',
            wait: '[to be reviewed]',
            success: '[Passed]',
            failed: '[Authentication failed]'
        },
        myteam: {
            teamNum: 'Team number:',
            directPush: 'Direct push number:',
            mytitle: 'my title',
            joinNum: 'Number of participants',
            rule1: 'Second contract rules',
            rule2: 'Planet Project Rules',
            i1: 'Second contract reward details',
            i2: 'Planet Project Reward Details',
            o1: 'Number of landlords',
            o2: 'Number of rich farmers under its banner',
            o3: 'Number of middle farmers under its banner',
            allNum: 'Total number of participants',
            yesIncome: 'Yesterdays total income',
            seeAll: 'all',
            seeDetail: 'View details',
            v0: 'Normal node',
            v1: 'Primary node',
            v2: 'Intermediate node',
            v3: 'Higher-order node',
            v4: 'Super Node',
            l0: 'General user',
            l1: 'middle peasant',
            l2: 'rich peasant',
            l3: 'Landlord'
        },
        payAccount: {
            addType: 'New type',
            addPayAccount: 'Add payment/receipt method',
            chooseBank: 'Choose bank',
            bank: "bank",
            bankName: 'Account opening bank',
            bankUser: 'Account opening name (name/unit name)',
            bankCard: 'bank card number',
            b1: 'Please enter the name of the account opening bank',
            b2: 'Please enter the account opening name or the name of the account opening unit',
            b3: 'Please enter your bank card number',
            realname: 'real name',
            alipayAccount: 'Alipay account',
            wepayAccount: 'WeChat account',
            paypalAccount: 'PayPal account',
            uploadCode: 'Upload QR code (optional)',
            p1: 'Please enter your real name',
            p2: 'Please enter your Alipay account number',
            p3: 'Please enter your WeChat account',
            p4: 'Please enter your PayPal account number',
            defaultBank: 'Bank of China',
            z1: '["Bank Card","Alipay","WeChat","PayPal"]',
            z2: '["Bank of China", "Industrial and Commercial Bank of China", "Agricultural Bank of China", "China Construction Bank", "Bank of Communications", "CITIC Bank", "China Everbright Bank", "China Minsheng Bank"," China Merchants Bank","Industrial Bank","Ping An Bank","Postal Savings Bank of China","Zheshang Bank"]',
        },
        coinaddr: {
            addCoinAddr: 'Add currency withdrawal address',
            mark: 'Remarks',
            p1: 'Please enter remarks',
            t1: 'Delete withdrawal address',
            t2: 'Are you sure to delete this withdrawal address? ',
            t3: 'Delete withdrawal address successfully! ',
        },
        worklist: {
            myworklist: 'My work order',
            newworklist: 'New work order',
            noworklist: 'Currently you have no work order record',
            mine: 'I:',
            service: 'Customer service:',
            reply: 'reply',
            open: 'Expand all replies',
            close: 'Collapse all replies',
            wait: 'pending',
            doing: 'processing',
            question: 'Select your question'
        },
        message: {
            unread: 'unread',
            read: 'read'
        },
        safe: {
            bindEmail: 'Bind mailbox',
            bindMobile: 'Bind mobile phone',
            modify: 'Enter modification',
            notBind: 'Not bound',
        }
    },
    form: {// form class
        email: 'email',
        mobile: 'Mobile phone number',
        username: 'Mobile phone or email',
        password: 'Login password',
        twice_password: 'Enter password again',
        newPassword: 'New login password',
        verifyCode: 'verification code',
        loginAccount: 'Login account',
        submit: 'submit',
        needMobile: "The mobile phone has not been bound yet, please bind the mobile phone first",
        needEmail: "The mailbox has not been bound yet, please bind the mailbox first",
        needMobileOrEmail: "You need to bind your mailbox and mobile phone first",

        signup: {
            submit: 'Register',
            mobile: 'Mobile registration',
            email: 'Email registration',
            recCode: 'Recommendation code (optional)',
            goSignin: 'Login account',
            bindEmail: 'Add new email binding',
            bindMobile: 'Add mobile phone binding',
            changePsw: 'Change login password',
            changeAssetsPsw: 'Change fund password',
            emailCode: 'Email verification code',
            e1: 'Please enter your email address',
            e2: 'Please enter the email verification code',
            m1: 'Please enter your mobile phone number',
            m2: 'Please enter the SMS verification code',
            registration_represents_consent: 'Registration represents consent',
            user_agreement: '"User Agreement"'
        },
        signin: {
            submit: 'Login',
            register: 'Register now',
            forget: 'forgot password'
        },
        forgot: {
            resetPsw: 'Reset password',
        },
        otc: {
            applyM: {
                applyMerchant: 'Apply for Merchant',
                merchantName: 'merchant name',
                t1: 'Please enter your business name',
                applyReason: 'Reason for application',
                t2: 'Please enter the reason for application (no more than 140 words)',
            },
            initiateAd: {
                tradeMode: 'Select trading mode',
                legalcurrency: 'Select legal currency',
                payWays: 'Select payment method',
                reciveWays: 'Select payment method',
                coin: 'Select currency',
                setCoinPrice: 'Set currency price',
                inputAmount: 'Enter the purchase amount',
                minAmount: 'Minimum amount',
                maxAmount: 'Maximum amount',
                remarks: 'Business message',
                t1: 'Please enter a message (no more than 140 characters)',
                goChoose: 'Go choose',
                limitAmount: 'Transaction limit',
                over: 'Transaction amount exceeds range',
                i1: 'Please enter the currency unit price'
            },
            state: {
                stateType: 'statement type',
                stateReason: 'State reason',
                i1: 'Please enter your order number',
                i2: 'Please enter the reason for your complaint (no more than 140 words)',
                evidence: 'Upload supporting documents (up to 4)',
                stateSuc: 'Appeal successful',
                stateFailed: 'Statement failed'
            }
        },
        assets: {
            cbResult: 'Stored value record',
            cbAddr: 'Stored value address',
            tbResult: 'Withdrawal record',
            zzResult: 'Transfer record',
            transaction_records: 'Transaction Records',
            tbAddr: 'Withdrawal address',
            txNum: 'Withdrawal quantity',
            chooseAddr: 'Choose address',
            tbNum: 'Withdrawal amount',
            fee: 'handling fee',
            arrival: 'actual arrival',
            tradePsw: 'Fund password',
            max: 'Maximum withdrawal',
            sms: 'SMS verification code',
            email: 'Email verification code',
            transfer: 'transfer',
            from: 'from',
            to: 'to',
            transferNum: 'Create the quantity',
            alltransfer: 'Transfer all',
            cantransfer: 'can transfer',
            saveCode: 'Long press the QR code to save',
            copyAddr: 'Copy address',
            t1: 'Warm reminder:',
            t2: '•The minimum deposit amount is:',
            t3: 'Please do not deposit any funds to the above address',
            t4: 'Asset. Otherwise it will not be retrieved. ',
            p1: 'Please use your mobile phone to scan the QR code',
            p2: 'Please enter the marked quantity',
            p3: 'Please enter the withdrawal address',
            p4: 'Please enter the withdrawal amount',
            p5: 'Please enter the fund password',
            p6: 'Please enter the verification code',
            s1: `Warm reminder:<br>
        • The minimum deposit amount is: {num}. Please do not deposit any non-asset to the above address. Otherwise it will not be retrieved.
        `,
            s2: `Warm reminder:<br>
        • The minimum withdrawal amount is:<b class="sellcolor">{lowerLimitOut} {symbol}</b>. <br>
        • To ensure the safety of funds, we will conduct manual review of currency withdrawals, please wait patiently;<br>
        • You can check the withdrawal status through the block browser. If the withdrawal does not arrive for a long time, please contact customer service to solve it manually;<br>
        • Please make sure your computer and browser are secure to prevent information from being tampered with or leaked. We recommend you use Google Chrome. `,
            s3: ``
        },
        account: {
            oldpwd: 'Original password',
            o1: 'Please enter the original login password',
            o2: 'Please enter the original fund password',
            newpwd: 'New password',
            n1: 'Please enter new login password',
            n2: 'Please enter a new fund password',
            repwd: 'Enter password again',
            r1: 'Please enter the new login password again',
            r2: 'Please enter a new fund password',
            repwd: 'Enter again',
            username: 'name',
            account: 'account',
            firstname: 'name',
            lastname: 'last name',
            please_enter_your_real_name: 'Please enter your real name',
            card_id: 'Identity card ID',
            please_enter_your_number: 'Please enter your ID number',
            id_photo: 'ID card photo',
            not_uploaded: 'Not uploaded',
            save: 'save',
            please_complete: 'Please complete the information or reselect the picture'
        },
        uploadImg: {
            chooseImg: 'Choose image',
            t1: 'The picture type must be one of .gif, jpeg, jpg, png, and bmp',
            t2: 'Upload pictures cannot exceed 5M'
        }
    },
    message: { // 提示类信息
        searchCoin: 'Search coin',
        home: {
            top: 'Optional'
        },
        sign: {

        },
        sms: {
            s1: "Code is send!"
        },
        invite: {
            success: "Receive success!",
        },
        copy: {
            success: "Copy Success.",
            failed: "Copy Failed, Please copy it manually."
        },
        nodata: 'No Record',
        pending: '【Application reviewing】',
        success: '【Successful application】',
        failed: '【Application failed】',
        upload: 'Image upload, please wait...',
        emailSuc: 'The email code is sent successfully, please check it!',
        otc: {

            needPayAccount: "Please add Pay account first",
            success: 'Successful application, please wait for review',
            failed: 'The reason for the application has exceeded the limit, please delete it.',
            i1: 'Your information is under review.<br/>Please be patient and will contact you within three to five business days.',
            i2: 'The application for the merchant failed.<br/>Please resubmit your application',
            i3: 'The advertisement you posted is under review.<br/>Please be patient and will contact you within three to five business days.',
            min: 'The minimum amount cannot be lower than 0',
            max: 'The maximum amount cannot be lower than 0',
            playSuc: 'Successfully released',
            require: 'Please fill in the required fields',
            confirmOrder: 'Confirm the order？',
            confirmPay: 'Are you sure you have paid?',
            cancelOrder: 'Confirm cancel the order?',
            p1: 'I have released, please check the balance in time.',
            p2: 'Sorry, I have cancelled the order.',
            p3: 'I have paid, please release it in time.',
            confirmCoin: 'Confirm to freed coin?',
            successCoin: 'Successful freed',
            removeSuc: 'Successful removed',
            over: 'Beyond the trading range'
        },
        assets: {
            t1: 'Recharge request has been initiated, waiting for administrator review',
            t2: 'Temporarily not allowed to transfer the same wallet',
            t3: 'The transferred funds must be greater than 0',
            t4: 'Transferred funds exceed the upper limit',
            t5: 'The amount of money requested can not be less than',
            t6: 'Please enter the correct fund password',
            t7: 'Withdrawal request has been issued, waiting for administrator review',
            t8: 'Adding a withdraw address successfully',
            t9: 'Please fill in the input',
            t10: 'The work order was submitted successfully!',
            transferSuc: 'Asset transfer success!',
            addFailed: 'Add failed',
            hasAdd: 'You have already added this payment method!',
            p1: 'The default payment method cannot be deleted',
            p2: 'Delete payment method',
            p3: 'Are you sure to delete the payment method?',
            deleteSuc: 'successfully deleted!',
            deleteFailed: 'failed to delete!',
        }
    },
    button: { // 按钮
        uploadSure: 'Confirm upload',
        trade: {
            gotrade: 'Go trade'
        },
        otc: {
            buy: 'BUY ',
            sell: 'SELL ',
            sure: 'Confirm',
            cancel: 'Cancel',
            buyNow: 'Buy Now',
            buySure: 'Confirm to buy',
            payNow: 'Pay Now',
            sellNow: 'Sell Now',
            cancelOrder: 'Cancel Order',
            freedcoin: 'Release coins',
            send: 'Send',
        },
        assets: {
            transferSure: 'Confirm Transfer',
            sendSms: 'Send',
            hasSend: 'Sent After ',
            reSend: 'Resend After ',
            sure: 'Confirm',
            submit: 'Submit',
            logout: 'Sign out',
            toC1: 'Go C1 certification',
            toC2: 'Go C2 certification',
            todo: 'Go add',
            redo: 'Recertification',
            redoC2: 'Go C2 certification again'
        }
    },
    validate: { // Validate category message
        public: {
            notEmpty: 'Human-computer verification initialization is not completed and will be refreshed~',
            formatError: 'Format error',
            geetest: 'Human-machine verification failed',
            smsError: 'Please enter the 4-digit verification code',
            inviteCode: 'Please enter the 7-digit invitation code',
            passwordError: "Please enter a password of 6-20 characters, uppercase and lowercase letters plus numbers",
            oldPswError: 'The original password was entered incorrectly and must contain English and numbers',
            newPswError: 'The new password is entered incorrectly and must contain English and numbers',
            notSame: 'Two password inputs are inconsistent',
            signin: 'Please log in first',
            emailError: 'The email format is incorrect',
            mobileError: 'Mobile phone number format is wrong',
            notNull: 'The input item cannot be empty',
            bindEmailSuc: 'Binding mailbox successfully! ',
            bindMobileSuc: 'Binding mobile phone successfully! ',
            changePswSuc: 'Login password changed successfully! ',
            changeAssetsPswSuc: 'Fund password changed successfully! ',
            changeAssetsPswToast: 'When setting the fund password for the first time, the original password is the login password',
            twicePassword: 'Two passwords are inconsistent'
        },
        signup: {
            usernameError: 'Please enter the correct email or phone number',
            success: "Registration successful!",
        },
        signin: {
            mobileError: "Please enter the correct phone number",
            emailError: 'Please enter the correct email address',
            requireError: 'Please enter required fields',
            success: 'Login successful! ',
            fail: 'The login information is incorrect or the human-machine verification failed'
        },
        forget: {
            success: "Password reset successful!",
            fail: "Password reset failed, please try again later"
        },
        smsStatus: {
            Send: "Send verification code",
            sendMail: "Send verification email",
            sending: "sent",
            resend: "Resend",
            sucess: 'Verification code sent successfully, please check carefully',
            error: "Failed to send verification code!"
        },
        robot: "Human-machine verification initialization failed",
        success: "The operation was successful!",
        fail: "Operation failed",
        suc: "success",
        err: "failed",
        sure: "confirm",
        needAll: "Please enter all required fields",
        logout: {
            title: 'User logout',
            content: 'Are you sure to log out? '
        },
        trade: {
            success: 'Pending order successful',
            fail: 'Pending order failed',
            noUse: 'No {coin} available',

            minBuy: 'Minimum buy {num} {coin}',
            maxBuy: 'Maximum purchase of {num} {coin}',
            buyAmount: 'Please enter the amount of {coin} to buy',
            buyPrice: 'Please enter the unit price to buy {coin}',

            maxSell: 'Maximum sellable {num} {coin}',
            minSell: 'Minimum sellable {num} {coin}',
            sellAmount: 'Please enter the amount of {coin} to sell',
            sellPrice: 'Please enter the unit price for selling {coin}',

            formatError: 'Format error',
            overflow: 'The difference between the unit price of your pending order and the current transaction price exceeds {count}%. Are you sure you want to submit it at the current price? ',
            messagePop: {
                title: 'Tips'
            },
            inputSuccess: 'Google Authenticator verification successful, valid for {time} minutes',
            coin: {
                funderror: 'Incorrect fund password entered',
                success: 'Authorization successful',
                orderSuc: 'Order placed successfully',
                withdrawalSuc: 'The order withdrawal application has been submitted successfully',
                betNum: 'Please select the order quantity',
                betSuccess: 'Order placed successfully',
                favSuc: 'Add optional successfully! ',
                favFailed: 'Cancel self-selection successfully'
            }
        },
        assets: {
            t1: 'Are you sure you have logged out? ',
            t2: 'Save avatar successfully! '
        }
    },
    rule: {
        s1: `<p>1. Select the trading product (BTC, ETH, LTC, BCH, EOS, XRP). </p>
        <p>2. Select the trading time from 1 minute to 5 minutes to 15 minutes to 30 minutes to 60 minutes (fast, suitable for a wide range of times, fast returns, and available for trading 24 hours a day). </p>
        <p>3. Set the transaction amount to 20USDT, 50 USDT, 100 USDT, 200 USDT, 500 USDT, 1000 USDT,
        2000 USDT (assuming an investment of 100 USDT, a return rate of 82%, if you make a profit, you can get 82 USDT + 100 USDT principal = 182 USDT income). Set the transaction amount to 5TUE, 10 TUE, 20 TUE, 30 TUE, 40 TUE, 50 TUE, 60 TUE, 70 TUE, 80 TUE, 90 TUE, 100 TUE, 200 TUE (assuming an investment of 100 TUE, a return rate of 82%, if The profit can be 82 TUE +100 TUE principal = 182 TUE income). </p>
        <p>4. Select the trading direction: two-way trading (up or down) to determine the price trend. You can click to buy "up" or click to buy "down". </p>
        <p>5. The transaction is settled when it expires. Once the transaction period is up, the losses and losses are automatically settled. </p>`,
        s2: `<p>The user bought the platform currency and deposited it into the Planet Project for locking. The amount that can be stored is: 500TUE, 1000TUE, 2000TUE, 3000TUE, 4000TUE, 5000TUE, 6000TUE, 7000TUE, 8000TUE, 9000TUEUE, FUEUE, UE
        The minimum deposit into an account is 500 TUE, and the maximum deposit is 20,000 TUE. After the planet project is launched, the exchange will give away 50% of the amount of spot that is used to start the planet project. The gifted spot can be traded in the secondary market at any time.
        For example, if a user normally buys 10,000 TUE and deposits it into the Planet Plan, the exchange will give away 5,000 TUE in cash. The lock-up period of the Planet Plan is 100 days. If the release is accelerated, the lock-up period will be 50 days.
        Release details:</p>
        <p>1: 1% is released by trading 5 lots per second contract every day (the period is from 00:00 a.m. every day to 00:00 the next day),</p>
        <p>2: 2% will be released by trading 10 lots per second contract every day (the period is from 00:00 a.m. every day to 00:00 the next day). </p>
        <p>The basis of the transaction is that each lot is not less than 1% of the Open Planet Project. For example:</p>
        <p>1: 5000TUE starts the Planet Plan, and the order amount for each second contract cannot be less than 50TUE. </p>
        <p>2: 10000TUE starts the planet plan, and the order amount for each second contract cannot be less than 100TUE,</p>
        <p>Selectable trading bases for TUE second contract: 5TUE, 10TUE, 20TUE, 30TUE, 40TUE, 50TUE, 60TUE, 70TUE, 80TUE, 90TUE, 100TUE, 200TUE,
        Transaction reference targets: BTC, ETH, LTC, BCH, EOS, XRP. </p>`
    },
    other: {
        translate1: 'setting',
        translate2: 'Profile settings',
        translate3: 'my',
        translate4: 'Contract transaction',
        translate5: 'Coin-to-coin transaction',
        translate6: 'Login',
        translate7: 'Available',
        translate8: 'Transaction amount',
        translate9: 'Go long',
        translate10: 'Go short',
        translate11: 'Stop loss price',
        translate12: 'Take profit price',
        translate13: 'Order modification',
        translate14: 'depth 5',
        translate15: 'depth 10',
        translate16: 'depth15',
        translate17: 'Default',
        translate18: 'Buy',
        translate19: 'Sell',
        translate20: 'Limit order',
        translate21: 'Market Order',
        translate22: 'Current order',
        translate23: 'All orders',
        translate24: 'Position price',
        translate25: 'Take profit price',
        translate26: 'Trading volume',
        translate27: 'Latest price',
        translate28: 'Stop loss price',
        translate29: 'Payment method',
        translate30: 'Margin',
        translate31: 'handling fee',
        translate32: 'Order type',
        translate33: 'Close position',
        translate34: 'Modify',
        translate35: 'Natural ordering',
        translate36: 'Automatically follow orders',
        translate37: 'Trial money',
        translate38: 'Dynamic rights',
        translate39: 'freeze',
        translate40: 'Floating profit and loss',
        translate41: 'Liquidation rate',
        translate42: 'Start time',
        translate43: 'End time',
        translate44: 'freeze',
        translate45: 'Order number',
        translate46: 'Leverage multiple',
        translate47: 'Position',
        translate48: 'Orders',
        translate49: 'Deal',
        translate50: 'Contract transaction',
        translate51: 'Buy',
        translate52: 'Sell',
        translate53: 'Quantity',
        translate54: 'status',
        translate55: 'Transaction volume',
        translate56: 'Order price',
        translate57: 'Undo',
        translate58: 'No data',
        translate59: 'Coin order',
        translate60: 'Historical commission',
        translate60: 'Historical Commission',
        translate61: 'Quickly Buy Coins',
        translate62: 'Invite',
        translate63: 'Support',
        translate64: 'Popular',
        translate65: 'success',
        translate66: 'failure',
        translate67: 'under review',
        translate68: 'address',
        translate69: 'currency',
        translate70: 'time',
        translate71: 'Withdrawal Currency',
        translate72: 'Transfer currency',
        translate73: 'Instructions',
        translate74: 'Please top up USDT-ERC20 on the Ether chain;',
        translate75: 'The user is responsible for any top-up errors;',
        translate76: 'The top-up time is about 1 minute;',
        translate77: 'Account selection',
        translate78: 'Completed',
        translate79: 'Funds',
        translate80: 'Price Limit',
        translate81: 'Market Price',
        translate82: 'Rise and fall',
        translate83: 'Take a clear picture of the front and back of your ID card',
        translate84: 'Make sure you are in a well-lit environment and take pictures of both the front and backpages of your passport.',
        translate85: 'Take photo',
        translate86: 'Verified',
        translate87: 'Stop Profit',
        translate88: 'Stop Loss',
        translate89: 'Next',
        translate90: 'Loading',
        translate91: 'Click to load more',
        translate92: 'All loaded',
        translate93: 'Platform Information ',
        translate94: 'Mailbox',
        translate95: 'Payment method management',
        translate96: 'Not set',
        translate97: 'Please set your payment method, please use your own ID account',
        translate98: 'Bank card account',
        translate99: 'Alipay account',
        translate100: 'WeChat account',
        translate101: 'SWIFT international remittance',
        translate102: 'Not bound',
        translate103: 'Name',
        translate104: 'Fund password',
        translate105: 'Please enter Alipay number',
        translate106: 'Please enter fund password',
        translate107: 'Bind Alipay account',
        translate108: 'Payment QR code',
        translate109: 'Save',
        translate110: 'Save successfully',
        translate111: 'Bank account',
        translate112: 'Branch account',
        translate113: 'Please select',
        translate114: 'Please enter the branch where you opened your account',
        translate115: 'Please enter your bank card number',
        translate116: 'Confirm your card number',
        translate117: 'Please enter your bank card number again',
        translate118: 'Cancel',
        translate119: 'Bind bank card account',
        translate120: 'Payment address',
        translate121: 'Please enter your WeChat account',
        translate122: 'Bind your WeChat account',
        translate123: 'Submit successfully',
        translate124: 'Please enter the amount you sell',
        translate125: 'Please enter the amount you buy',
        translate126: 'The transaction amount cannot be higher than',
        translate127: 'The amount you buy cannot be higher than',
        translate128: 'Please enter the selling price',
        translate129: 'The maximum amount you can sell',
        translate130: 'Pieces',
        translate131: 'Order cancellation successful',
        translate132: 'Successfully modified',
        translate133: 'Successfully modified',
        translate134: 'Price change',
        translate135: 'Highest',
        translate136: 'Lowest',
        translate137: 'Please enter your username',
        translate138: 'Please enter your password',
        translate139: 'Or continue',
        translate140: 'Log in to Web3',
        translate141: 'Enter your username and password',
        translate142: 'Please enter your password again',
        translate143: 'Create an account',
        translate144: 'Register',
        translate145: 'Already have an account',
        translate146: 'By clicking Continue, you agree to our',
        translate147: 'Terms of Service',
        translate148: 'Privacy Policy',
        translate149: 'And',
        translate150: 'Register',
        translate151: 'Don‘t have any account?',
        translate152: 'Connect wallet',
        translate153: 'Two passwords do not agree ',
        translate154: 'Please top up USDT',
        translate155: 'All',
        translate156: 'Please enter the amount',
        translate157: 'Submission successful, waiting for review! ',
        translate158: 'Still under review, please wait patiently',
        translate159: 'Already authenticated, cannot be authenticated again',
        translate160: 'Recharge successful',
        translate161: 'Deposit USDT-ERC20',
        translate162: 'Enter the amount',
        translate163: 'Get the deposit address',
        translate164: 'To ensure that the funds arrive quickly and safely, we will give you a set of random digits',
        translate165: 'Please transfer the amount below, otherwise it will not be received and you will bear the consequences yourself',
        translate166: 'Deposit address',
        translate167: 'I have copied the information',
        translate168: 'Deposit attention',
        translate169: 'Copy successfully!',
        translate170: 'Copy failed!',
        translate171: 'The recharge amount cannot be empty',
        translate172: 'No more',
        translate173: 'Loading...',
        translate174: 'Please enter an integer greater than 0',
        translate175: 'M',
        translate176: 'S',
        translate177: 'Countdown',
    }
};
export default lang;
